import {RootState} from "../types"
import {Pensiondetails,
		IncomeItem,
		AllianzPolicy,
		AllianzPensionPlan,
		AOW,
		PolicyTransactionInfo,
		PensionEventPayload,
		PensionPayloadEvent,
		CapitalInfo,
		RiskProfileNamePayload,
		AmountGroup,
		RiskProfileToCalculate,
		PlannerChoicesPayload,
		PlannerResult,
		RiskProfileShortCodes,
		riskProfileShortCodes,
		RiskProfileCodeSet,
		Age,
		WhatIfTotals,
		HistoricalLifecycle,
		HistoricalLifecycleSet,
		HistoricalRiskProfileCodeSet,
		AllianzRetireePolicy,
		BenefitType} from "./types"
import {Module} from "vuex"
import {isUndefined, sumBy} from "lodash-es"

import {participant as participantApi, calculator as calculatorApi, custom as customApi} from "api/gateway"
import {convertIncomeItem, convertTransactionDateGroup, convertAmountGroup, convertPensionPlan, convertUnitPrices} from "./conversion"
import { historicalLifecycles, riskProfilesToExclude, riskProfileOverrides } from "./staticData"

import diff, {Diff} from "lib/date/diff"
import store from "../index"
import {PensionProfilePayload, initialPensionProfileState, RiskProfileForCalculation, } from "store/state/types"
import formatISO from "lib/date/formatISO"

// There is a seperate riskProfileForVariableCharts for the CJ VariablePension.
// In CJ VariablePension only policies with more then 10 riskprofiles apply.
const ALLIANZ_ADVANTAGE_PRODUCT_ID = "26"
const PPI_PRODUCT = ["PPI", "PPI_2023"]

const pensiondetails: Pensiondetails = {
	aow: {
		title: "AOW",
		id: "12345",
		civilStatusNpr: undefined,
		typeOfAOW: undefined,
		single: 0,
		partner: 0,
		removable: false,
		editable: false,
		startDate: new Date(0),
		referenceDate: new Date(0)
	},
	statePensionAge: {years: 0, months: 0},
	incomeItems: [],
	allianzPensionPolicies: [],
	allianzRetireePolicies: [],
	allianzPensionPlans: [],
	fundsLoaded: false,
	zeroAlterationAmountsLoaded: false,
	endparticipationAmountsLoaded: false,
	riskChartAmountsLoaded: false,
	riskProfileCharts: {
		DEF: { title: "A", pessimistic: 0, expected: 0, optimistic: 0 },
		NEU: { title: "B", pessimistic: 0, expected: 0, optimistic: 0 },
		OFF: { title: "C", pessimistic: 0, expected: 0, optimistic: 0 }
	},
	riskProfileForVariableCharts: {
		DEF: { title: "A", pessimistic: 0, expected: 0, optimistic: 0 },
		NEU: { title: "B", pessimistic: 0, expected: 0, optimistic: 0 },
		OFF: { title: "C", pessimistic: 0, expected: 0, optimistic: 0 }
	},
	policyRiskProfileChartLoaded: [],
	policyVariableChartLoaded: [],
	isEdited: false
}
const requiresLoading = (policy: AllianzPolicy, pensionPayloadEvent: string): boolean => !(
	policy.status === "ExPartnerPension" ||
	policy.status === "Retained" || // PDE-16153 'overgedragen' in lal
	(policy.status === "Active" && policy.currentPremium === 0) ||
	(policy.status === "Sleeper" && pensionPayloadEvent === "PensionEventEndParticipation")
)

const recalculateCapitals = (capitals: AmountGroup) => {
	capitals.pessimisticCapital = capitals.pessimisticCapital * capitals.pessimisticRealAnnuity / capitals.pessimisticAnnuity
	capitals.expectedCapital = capitals.expectedCapital * capitals.expectedRealAnnuity / capitals.expectedAnnuity
	capitals.optimisticCapital = capitals.optimisticCapital * capitals.optimisticRealAnnuity / capitals.optimisticAnnuity
	return capitals
}

const pensiondetailsModule: Module<Pensiondetails, RootState> = {
	actions: {
		async uploadXML(state, xml: File): Promise<void> {
			if (xml.type !== "text/xml") {
				throw Error("NOT_XML")
			}

			const pensionDetails = await participantApi.postNPR(xml)
			const incomeItems: Array<IncomeItem> = []
			state.commit("setAOW", pensionDetails.state)

			if (pensionDetails.external && pensionDetails.external.length) {
				pensionDetails.external.filter(item => !!item.startDate).map<IncomeItem>(item => ({
					id: item.id,
					type: "PENSION",
					name: item.register ? item.register.provider : "",
					reference: item.register ? item.register.identifier : "",
					startDate: item.startDate ? item.startDate : null,
					endDate: item.endDate ? item.endDate : null,
					benefit: item.benefit,
					referenceDate: item.referenceDate,
					fiscal: "GROSS",
					removable: false,
					editable: false,
					indicative: item.type === "DEFINED_CONTRIBUTION"
				})).forEach(item => {
					incomeItems.push(item)
				})

				state.commit("setNPRIncomeItems", incomeItems)

				let pensionProfileState: PensionProfilePayload = initialPensionProfileState
				pensionProfileState = await store.dispatch("progressState/getProgressState", pensionProfileState)
				pensionProfileState.steps[6].endDateTime = pensionDetails.creationDate
				await store.dispatch("progressState/updateProgressState", pensionProfileState)
			}
		},
		async savePrivateBenefit(state, incomeItem: IncomeItem): Promise<void> {

			const incomeItemSave = await participantApi.savePrivateBenefit(incomeItem)

			if (incomeItemSave) {
				state.commit("removeIncome", incomeItem)
				incomeItemSave.editable = true
				incomeItemSave.removable = true
				state.commit("replaceIncome", convertIncomeItem(incomeItemSave))

				let pensionProfileState: PensionProfilePayload = initialPensionProfileState
				pensionProfileState = await store.dispatch("progressState/getProgressState", pensionProfileState)
				pensionProfileState.steps[7].endDateTime = new Date()
				await store.dispatch("progressState/updateProgressState", pensionProfileState)
			}
		},
		async deletePrivateBenefit(state, incomeItem: IncomeItem): Promise<void> {
			await participantApi.deletePrivateBenefit(incomeItem)
			state.commit("removeIncome", incomeItem)
		},
		async totalMonthlyNet(state, additionalSavings: number | undefined) {
			const totalGross = additionalSavings ? state.rootGetters["pensiondetails/totalGross"] + additionalSavings : state.rootGetters["pensiondetails/totalGross"]
			const totalNet = state.rootGetters["pensiondetails/totalNet"]

			const calculatedNet = await calculatorApi.netAmount(totalGross, "pension", state.getters.isSingle)
			const totalAnnualNet = totalNet + calculatedNet
			return Math.round(totalAnnualNet / 12)
		},
		async loadAllianzPensionPlan(_context, payload: any): Promise<AllianzPensionPlan> {
			const pensionPlan = await participantApi.getPensionPlan({product: payload.product, pensionPlanID: payload.pensionPlanID})
			return convertPensionPlan(pensionPlan)
		},
		async loadRiskProfileResult(_context, payload: RiskProfileForCalculation): Promise<any> {
			const riskProfileResult = await participantApi.getRiskProfileResult(payload)
			return riskProfileResult
		},
		async loadPlannerResult(_context, payload: PlannerChoicesPayload): Promise<PlannerResult | null> {
			const plannerResult = await participantApi.calculatePlannerResult(payload)
			return plannerResult
		},
		async loadFunds(state) {
			const isRetired = state.rootGetters["policyholder/isRetired"]
			const policies: Array<AllianzPolicy | AllianzRetireePolicy> = isRetired ?
				state.state.allianzRetireePolicies.filter(policy => [BenefitType.DIP_VARIABLE, BenefitType.DIL_VARIABLE].includes(policy.type)) :
				state.state.allianzPensionPolicies

			const getTransactions = async (policy: { id: string }): Promise<void> => {
				const policyTransactions = convertTransactionDateGroup(
					await participantApi.getFund({ policyID: policy.id }, isRetired)
				)

				if (policyTransactions) {
					const policyTransactionInfo: PolicyTransactionInfo = {
						policyId: policy.id,
						transactions: policyTransactions,
						policySum: 0
					}
					state.commit("setAllianzPolicyTransactions", policyTransactionInfo)
				}
			}

			await Promise.all(policies.map(getTransactions))

			state.commit("setFundsLoaded", true)
		},
		async loadUnitPrices(_state, payload: {dateFrom: Date, dateTo: Date}): Promise<any> {
			return convertUnitPrices(await customApi.getUnitPricesByDateRange(payload))
		},
		async loadCapitals(state, pensionPayloadEvent: PensionPayloadEvent) {
			const filteredPolicies = state.state.allianzPensionPolicies.filter(policy => requiresLoading(policy, pensionPayloadEvent))

			const loadPolicyCapitals = async (policy: AllianzPolicy): Promise<void> => {
				// PDE-15219 - always calculate on the 1st of the month
				const alterationDateRaw = new Date()
				alterationDateRaw.setDate(1)
				const alterationDate = formatISO(alterationDateRaw)

				const pensionEventPayload: PensionEventPayload = {
					participationKey: Number(policy.id),
					pensionEvent: pensionPayloadEvent,
					ucmCalculation: true,
					alterationDate,
					pensionDate: formatISO(policy.pensioenDatum)
				}
				if (policy.riskProfileOverride !== policy.riskProfileName && pensionPayloadEvent === "PensionEventZeroAlteration") {
					const riskProfileKey: string = await state.dispatch(
						"getRiskProfileKeyByPolicyAndName",
						{ policy, riskProfileNewCode: policy.riskProfileOverride }
					)
					pensionEventPayload.pensionEvent = "PensionEventSimulation"
					pensionEventPayload.riskProfileKey = riskProfileKey
				}

				const loadedCapitals = convertAmountGroup(await participantApi.getPensionCapitalAnnuity(pensionEventPayload))

				if (!loadedCapitals) {
					return
				}

				const capitalInfo: CapitalInfo = {
					policyId: policy.id,
					capitals: loadedCapitals
				}

				if (pensionPayloadEvent === "PensionEventZeroAlteration") {

					// Recalculate to real capitals for PensionEventZeroAlteration
					capitalInfo.capitals = recalculateCapitals(capitalInfo.capitals)
					state.commit("setAllianzPolicyZeroAlterationAmounts", capitalInfo)

					// EndOfParticipationCall gives an empty response for status "Sleeper".
					// Same amounts as PensionEventZeroAlteration can be used here
					if (policy.status === "Sleeper") {
						state.commit("setAllianzPolicyEndparticipationAmounts", capitalInfo)
					}

				} else {
					// No recalculation to real capitals needed for PensionEventEndParticipation
					state.commit("setAllianzPolicyEndparticipationAmounts", capitalInfo)
				}
			}

			await Promise.all(filteredPolicies.map(loadPolicyCapitals))

			const policyHolderMainStatus = state.rootGetters["pensiondetails/policyHolderMainStatus"]

			if (pensionPayloadEvent === "PensionEventZeroAlteration") {

				state.commit("setZeroAlterationAmountsLoaded", true)

				// EndOfParticipationCall gives an empty response for status "Sleeper" so set boolean for loaded to true
				if (policyHolderMainStatus === "Sleeper") {
					state.commit("setEndparticipationAmountsLoaded", true)
				}
			} else {
				state.commit("setEndparticipationAmountsLoaded", true)
			}

		},
		async loadRiskProfileCapitals(_state, riskProfileToCalculate: RiskProfileToCalculate) {
			const amountGroupObject: AmountGroup = {
				pessimisticCapital: 0,
				expectedCapital: 0,
				optimisticCapital: 0,
				pessimisticAnnuity: 0,
				expectedAnnuity: 0,
				optimisticAnnuity: 0,
				pessimisticRealAnnuity: 0,
				expectedRealAnnuity: 0,
				optimisticRealAnnuity: 0
			}

			const capitalInfo: CapitalInfo = {
				policyId: riskProfileToCalculate.policyId,
				capitals: amountGroupObject
			}

			// PDE-15219 - always calculate on the 1st of the month
			const alterationDateRaw = new Date()
			alterationDateRaw.setDate(1)
			const alterationDate = formatISO(alterationDateRaw)

			const pensionEventSimulationPayload: PensionEventPayload = {
				participationKey:  Number(riskProfileToCalculate.policyId),
				pensionEvent: "PensionEventSimulation",
				ucmCalculation: true,
				alterationDate,
				pensionDate: riskProfileToCalculate.pensionDate,
				riskProfileKey: riskProfileToCalculate.riskProfileKey
			}

			const loadedCapitals = convertAmountGroup(await participantApi.getPensionCapitalAnnuity(pensionEventSimulationPayload))

			if (loadedCapitals) {
				capitalInfo.capitals = loadedCapitals
			}

			return capitalInfo
		},
		calculateCapitalAnnuityTotals(state, key: "zeroAlterationAmounts" | "endOfParticipationAmounts") {
			const amountTotals: AmountGroup = {
				pessimisticCapital: 0,
				expectedCapital: 0,
				optimisticCapital: 0,
				pessimisticAnnuity: 0,
				expectedAnnuity: 0,
				optimisticAnnuity: 0,
				pessimisticRealAnnuity: 0,
				expectedRealAnnuity: 0,
				optimisticRealAnnuity: 0
			}
			for (const allianzPolicy of state.state.allianzPensionPolicies) {
				if (allianzPolicy[key] && Object.entries(allianzPolicy[key]).length > 0) {
					amountTotals.pessimisticCapital += allianzPolicy[key].pessimisticCapital,
					amountTotals.expectedCapital += allianzPolicy[key].expectedCapital,
					amountTotals.optimisticCapital += allianzPolicy[key].optimisticCapital,
					amountTotals.pessimisticAnnuity += allianzPolicy[key].pessimisticAnnuity,
					amountTotals.expectedAnnuity += allianzPolicy[key].expectedAnnuity,
					amountTotals.optimisticAnnuity += allianzPolicy[key].optimisticAnnuity,
					amountTotals.pessimisticRealAnnuity += allianzPolicy[key].pessimisticRealAnnuity,
					amountTotals.expectedRealAnnuity += allianzPolicy[key].expectedRealAnnuity,
					amountTotals.optimisticRealAnnuity += allianzPolicy[key].optimisticRealAnnuity
				}
			}

			return amountTotals
		},
		calculateWhatIfTotals(state) {
			const whatIfTotals: WhatIfTotals = {
				pp_Verzekerd: 0,
				wzp_Verzekerd: 0,
				anw: 0,
				waoHiaat: 0,
				waoExcedent: 0
			}
			for (const allianzPolicy of state.state.allianzPensionPolicies) {
				whatIfTotals.pp_Verzekerd = whatIfTotals.pp_Verzekerd + allianzPolicy.pp_Verzekerd + allianzPolicy.voluntaryPartnerPension
				whatIfTotals.wzp_Verzekerd = whatIfTotals.wzp_Verzekerd + allianzPolicy.wzp_Verzekerd
				whatIfTotals.anw = whatIfTotals.anw + allianzPolicy.anw
				whatIfTotals.waoHiaat = whatIfTotals.waoHiaat + allianzPolicy.waoHiaat
				whatIfTotals.waoExcedent = whatIfTotals.waoExcedent + allianzPolicy.waoExcedent
			}
			return whatIfTotals
		},
		async loadRiskProfileCharts(state) {
			const filteredPolicies = state.state.allianzPensionPolicies.filter(policy => requiresLoading(policy, ""))

			const loadWithShortCode = async (policy: AllianzPolicy, riskProfileCodeSet: RiskProfileCodeSet) => {
				state.commit("sumRiskCharts", {
					policyId: policy.id,
					riskProfileShortCode: riskProfileCodeSet.currentRiskProfileShortCode,
					capitals: policy.zeroAlterationAmounts
				})

				// SET riskProfileForVariableCharts for the CJ VariablePension.
				if (policy.pensionPlanInfo.hasMin10RiskProfiles) {
					state.commit("sumRiskVariableCharts", {
						policyId: policy.id,
						riskProfileShortCode: riskProfileCodeSet.currentRiskProfileShortCode,
						capitals: policy.zeroAlterationAmounts
					})
				}

				const loadRiskProfileCapitalsByShortCode = async (riskProfileShortCode: RiskProfileShortCodes): Promise<void> => {
					const riskProfileNewCode = policy.riskProfileOverride.replace(`_${riskProfileCodeSet.currentRiskProfileShortCode}`, `_${riskProfileShortCode}`)
					const riskProfileKey = await state.dispatch("getRiskProfileKeyByPolicyAndName", {policy, riskProfileNewCode})

					const riskProfileToCalculate: RiskProfileToCalculate = {
						policyId: policy.id,
						pensionDate: formatISO(policy.pensioenDatum),
						riskProfileKey
					}

					const riskProfileCapitals = await state.dispatch("loadRiskProfileCapitals", riskProfileToCalculate)

					// Only annuities are used in the RiskCharts so no reason to recalculate the capitals here.
					state.commit("sumRiskCharts", { policyId: policy.id, riskProfileShortCode, capitals: riskProfileCapitals.capitals })

					// SET riskProfileForVariableCharts for the CJ VariablePension.
					if (policy.pensionPlanInfo.hasMin10RiskProfiles) {
						state.commit("sumRiskVariableCharts", {policyId: policy.id, riskProfileShortCode, capitals: riskProfileCapitals.capitals})
					}
				}

				await Promise.all(riskProfileCodeSet.remainingRiskProfileShortCodes.map(loadRiskProfileCapitalsByShortCode))
			}
			const loadWithoutShortCode = async (policy: AllianzPolicy) => {
				const historicalRiskProfileCodeSet: HistoricalRiskProfileCodeSet = await state.dispatch("getHistoricalRiskProfileCode", policy.riskProfileName)

				if (historicalRiskProfileCodeSet.currentRiskProfileShortCode && historicalRiskProfileCodeSet.currentLifeCycle) {
					state.commit("sumRiskCharts", {
						policyId: policy.id,
						riskProfileShortCode: historicalRiskProfileCodeSet.currentRiskProfileShortCode,
						capitals: policy.zeroAlterationAmounts
					})

					// SET riskProfileForVariableCharts for the CJ VariablePension.
					if (policy.pensionPlanInfo.hasMin10RiskProfiles) {
						state.commit("sumRiskVariableCharts", {
							policyId: policy.id,
							riskProfileShortCode: historicalRiskProfileCodeSet.currentRiskProfileShortCode,
							capitals: policy.zeroAlterationAmounts
						})
					}

					for (const historicalRiskProfileShortCode of historicalRiskProfileCodeSet.remainingRiskProfileShortCodes) {
						const historicalRiskProfileNewCode = historicalRiskProfileCodeSet.currentLifeCycle.lifecycles.find(
							(lifecycle: HistoricalLifecycleSet) => lifecycle.code === historicalRiskProfileShortCode)!.name
						const historicalRiskProfileKey = await state.dispatch("getRiskProfileKeyByPolicyAndName", {
							policy,
							riskProfileNewCode: historicalRiskProfileNewCode
						})

						const historicalRiskProfileToCalculate: RiskProfileToCalculate = {
							policyId: policy.id,
							pensionDate: formatISO(policy.pensioenDatum),
							riskProfileKey: historicalRiskProfileKey
						}

						const historicalRiskProfileCapitals = await state.dispatch("loadRiskProfileCapitals", historicalRiskProfileToCalculate)
						const sumPayload = {
							policyId: policy.id,
							riskProfileShortCode: historicalRiskProfileShortCode,
							capitals: historicalRiskProfileCapitals.capitals
						}
						// Only annuities are used in the RiskCharts so no reason to recalculate the capitals here.
						state.commit("sumRiskCharts", sumPayload)

						// SET riskProfileForVariableCharts for the CJ VariablePension.
						if (policy.pensionPlanInfo.hasMin10RiskProfiles) {
							state.commit("sumRiskVariableCharts", sumPayload)
						}
					}
				}
			}

			const loadRiskProfileCharts = async (policy: AllianzPolicy): Promise<void> => {
				const riskProfileCodeSet: RiskProfileCodeSet = await state.dispatch("getCurrentRiskProfileCode", policy.riskProfileOverride)
				const load = riskProfileCodeSet.currentRiskProfileShortCode !== null ? loadWithShortCode : loadWithoutShortCode
				await load(policy, riskProfileCodeSet)
			}

			await Promise.all(filteredPolicies.map(loadRiskProfileCharts))

			state.commit("setRiskChartAmountsLoaded", true)

		},
		getCurrentRiskProfileCode(_state, riskProfileName: string): RiskProfileCodeSet {
			const riskProfileShortCodesToCheck = [...riskProfileShortCodes]
			let riskProfileCodeSet: RiskProfileCodeSet = {
				currentRiskProfileShortCode: null,
				remainingRiskProfileShortCodes: []
			}

			for (const riskProfileShortCode of riskProfileShortCodesToCheck) {
				if (riskProfileName.includes(`_${riskProfileShortCode}`)) {
					riskProfileCodeSet = {
						currentRiskProfileShortCode: riskProfileShortCode as RiskProfileShortCodes,
						remainingRiskProfileShortCodes: riskProfileShortCodesToCheck.filter(riskProfileCode =>
						riskProfileCode !== riskProfileShortCode) as Array<RiskProfileShortCodes>
					}
				}
			}

			return riskProfileCodeSet
		},
		getHistoricalRiskProfileCode(_state, riskProfileName: string): RiskProfileCodeSet {
			const historicalRiskProfileCodeSet: HistoricalRiskProfileCodeSet = {
				currentRiskProfileShortCode: null,
				remainingRiskProfileShortCodes: [],
				currentLifeCycle: undefined
			}

			historicalRiskProfileCodeSet.currentLifeCycle = historicalLifecycles.find((lifecycleGroup: HistoricalLifecycle) =>
			lifecycleGroup.lifecycles.find((lifecycle: HistoricalLifecycleSet) => lifecycle.name === riskProfileName))

			if (historicalRiskProfileCodeSet.currentLifeCycle) {

				const riskProfileShortCodesToCheck = [...riskProfileShortCodes]

				historicalRiskProfileCodeSet.currentRiskProfileShortCode = historicalRiskProfileCodeSet.currentLifeCycle.lifecycles.find(
					(lifecycle: HistoricalLifecycleSet) => lifecycle.name === riskProfileName)!.code

				historicalRiskProfileCodeSet.remainingRiskProfileShortCodes = riskProfileShortCodesToCheck.filter(riskProfileCode =>
					riskProfileCode !== historicalRiskProfileCodeSet.currentRiskProfileShortCode) as Array<RiskProfileShortCodes>
			}

			return historicalRiskProfileCodeSet
		},
		getRiskProfileKeyByPolicyAndName(_state, data: {policy: AllianzPolicy, riskProfileNewCode: string}) {
			 if (data.policy.pensionPlanInfo.availableRiskProfiles) {
				const foundProfiles = data.policy.pensionPlanInfo.availableRiskProfiles.filter(riskProfile => riskProfile.name === data.riskProfileNewCode)
				return foundProfiles.length ? foundProfiles[0].key : ""
			}
				return ""
		},
		setTypeOfAOW(state, value: any) {
			state.commit("setTypeOfAOW", value)
		}
	},
	getters: {
		retireePolicies: (state): Array<AllianzRetireePolicy> => state.allianzRetireePolicies,
		retireePension: (state): number => sumBy(state.allianzRetireePolicies, policy => policy.grossPerYear) ?? 0,
		retireeInvestmentAmount: (state): number => {
			const matchedPolicies = state.allianzRetireePolicies.filter(policy => [BenefitType.DIP_VARIABLE, BenefitType.DIL_VARIABLE].includes(policy.type))
			return sumBy(matchedPolicies, policy => sumBy(policy.currentFunds, fund => fund.value))
		},
		hasRetireeFixedPolicy: (state): boolean => {
			return !isUndefined(state.allianzRetireePolicies.find(policy => [BenefitType.DIP_VAST, BenefitType.DIL].includes(policy.type)))
		},
		hasRetireeVariablePolicy: (state): boolean => {
			return !isUndefined(state.allianzRetireePolicies.find(policy => [BenefitType.DIP_VARIABLE, BenefitType.DIL_VARIABLE].includes(policy.type)))
		},
		isSingle: (state, _get, rootState, rootGetters): boolean => {
			const noPartnerDataPresent = !rootGetters["policyholder/currentPartner"]

			return noPartnerDataPresent ?
				state.aow.typeOfAOW ? state.aow.typeOfAOW === "SINGLE"
					: state.aow.civilStatusNpr ? state.aow.civilStatusNpr === "Alleenstaand"
						: rootState.policyholder!.civilStatus ? rootState.policyholder!.civilStatus === "SINGLE"
							: true
				: false
		},
		aowItem: (state, get): IncomeItem => {
			return ({
				id: state.aow.id ? state.aow.id : "",
				type: "STATE_PENSION",
				name: get.isSingle  ? "aowNoPartner" : "aowWithPartner",
				benefit: get.isSingle ? state.aow.single : state.aow.partner,
				startDate: state.aow.startDate,
				referenceDate: state.aow.referenceDate,
				endDate: null,
				fiscal: "GROSS",
				removable: state.aow.removable,
				editable: state.aow.editable
			})
		},
		incomeItems: (state, get) => [...state.incomeItems.filter(item => item.startDate !== null), get.aowItem as IncomeItem],
		pensionItems: state => state.incomeItems.filter(i => i.type === "PENSION"),
		totalAllianzPension: state => state.allianzPensionPolicies.reduce((acc, curr) => acc + curr.annual, 0),
		totalOtherIncomes: (_state, get, rootState) => {
			return sumBy<IncomeItem>(get.incomeItems, item =>
			 				(diff(Diff.YEARS, rootState.policyholder!.personal.birthdate, item.startDate!)) < 69 ? item.benefit : 0)
		},
		isEdited: state => state.isEdited,
		totalGross: (_state, get, rootState) =>
			sumBy<IncomeItem>(get.incomeItems.filter((income: IncomeItem) => income.fiscal === "GROSS" &&
			(diff(Diff.YEARS, rootState.policyholder!.personal.birthdate, income.startDate!)) < 69),
			item => item.benefit) + get.totalAllianzPension,
		totalNet: (_state, get) =>
			sumBy<IncomeItem>(get.incomeItems.filter((income: IncomeItem) => income.fiscal === "NET"), item => item.benefit),
		policiesWithMoreThan10RProfiles: (state): Array<AllianzPolicy> =>  {

			const allianzPoliciesWithMoreThan10RProfiles: Array<AllianzPolicy> = []

			for (const allianzPolicy of state.allianzPensionPolicies) {
				if (allianzPolicy.pensionPlanInfo && allianzPolicy.pensionPlanInfo.hasMin10RiskProfiles) {
					allianzPoliciesWithMoreThan10RProfiles.push(allianzPolicy)
				}
			}
			return allianzPoliciesWithMoreThan10RProfiles
		},
		policiesWithMoreThan3LessThan10RProfiles: (state): Array<AllianzPolicy> =>  {
			const policiesWithMoreThan3LessThan10RProfiles: Array<AllianzPolicy> = []
			for (const allianzPolicy of state.allianzPensionPolicies) {
				if (allianzPolicy.pensionPlanInfo.availableRiskProfiles &&
					allianzPolicy.pensionPlanInfo.availableRiskProfiles.length > 3 &&
					allianzPolicy.pensionPlanInfo.availableRiskProfiles.length < 10) {
						policiesWithMoreThan3LessThan10RProfiles.push(allianzPolicy)
				}
			}
			return policiesWithMoreThan3LessThan10RProfiles
		},
		policiesWithLessThan3RProfiles: (state): Array<AllianzPolicy> =>  {
			const policiesWithLessThan3RProfiles: Array<AllianzPolicy> = []
			for (const allianzPolicy of state.allianzPensionPolicies) {
				if (allianzPolicy.pensionPlanInfo.availableRiskProfiles &&
					allianzPolicy.pensionPlanInfo.availableRiskProfiles.length < 3) {
						policiesWithLessThan3RProfiles.push(allianzPolicy)
				}
			}
			return policiesWithLessThan3RProfiles
		},
		hasOldAgePension: (state, get): boolean => {
			if (get.retireePension > 0) {
				return true
			}

			return state.allianzPensionPolicies.some(policy => policy.hasOldAgePension)
		},
		policiesWithOwnInvestmentStrategy: (state): boolean => {
			return state.allianzPensionPolicies.some(policy => riskProfilesToExclude.has(policy.riskProfileName))
		},
		policyHolderMainStatus: (state): string => {

			const policies = state.allianzPensionPolicies

			if (policies.some(policy => policy.status === "Active")) {
				return "Active"
			} else if (policies.some(policy => policy.status === "Sleeper")) {
				return "Sleeper"
			} else if (policies.some(policy => policy.status === "Pensioner")) {
				return "Pensioner"
			}
			return "NOACCESS"
		},
		fundSaldo: (state): number => {
			return sumBy(state.allianzPensionPolicies, allianzPolicy => allianzPolicy.insurancesBalance)
		},
		isExtraSavingsAvailable: (state): boolean =>  {
			return state.allianzPensionPolicies.some(policy => policy.pensionPlanInfo.bijsparen_Regeling && policy.status === "Active")
		},
		hasAllianzAdvantageProduct: (state): boolean => {
			return state.allianzPensionPolicies.some(policy => policy.product === ALLIANZ_ADVANTAGE_PRODUCT_ID)
		},
		hasPPIProduct: (state): boolean => {
			return state.allianzPensionPolicies.some(policy => PPI_PRODUCT.includes(policy.product))
		}
	},
	mutations: {
		hydrate(state, data: Pensiondetails) {
			if (data && !state.isEdited) {
				Object.assign(state, data)
			}
		},
		setAllianzPolicies(state, data: Array<AllianzPolicy>) {
			state.allianzPensionPolicies = data
		},
		setRetireePolicies(state, data: Array<AllianzRetireePolicy>) {
			state.allianzRetireePolicies = data
		},
		setAllianzPensionPlan(state, data: {policyId: string, pensionPlanInfo: AllianzPensionPlan}) {
			const index = state.allianzPensionPolicies.findIndex(allianzPolicy => allianzPolicy.id === data.policyId)
			if (index !== -1) {
				state.allianzPensionPolicies[index].pensionPlanInfo = data.pensionPlanInfo
			}
		},
		setAllianzPolicyRiskProfileName(state, data: RiskProfileNamePayload) {
			const policy = state.allianzPensionPolicies.find(allianzPolicy => allianzPolicy.id === data.policyId)
			if (policy) {
				policy.riskProfileName = data.riskProfileName
				policy.riskProfileOverride = riskProfileOverrides.get(data.riskProfileName) || data.riskProfileName
			}
		},
		setAllianzPolicyTransactions(state, data: PolicyTransactionInfo) {
			const policies: Array<AllianzPolicy | AllianzRetireePolicy> = state.allianzRetireePolicies.length > 0 ?
				state.allianzRetireePolicies.filter(policy => [BenefitType.DIP_VARIABLE, BenefitType.DIL_VARIABLE].includes(policy.type)) :
				state.allianzPensionPolicies
			const index = policies.findIndex(policy => policy.id === data.policyId)
			if (index !== -1) {
				policies[index].transactions = data.transactions
			}
		},
		setAllianzPolicyZeroAlterationAmounts(state, data: CapitalInfo) {
			const index = state.allianzPensionPolicies.findIndex(allianzPolicy => allianzPolicy.id === data.policyId)
			if (index !== -1) {
				state.allianzPensionPolicies[index].zeroAlterationAmounts = data.capitals
				data.capitals.expectedRealAnnuity ? state.allianzPensionPolicies[index].annual = Math.round(data.capitals.expectedRealAnnuity * 12) :
				state.allianzPensionPolicies[index].annual = 0
				state.allianzPensionPolicies[index].expectedCapital = Math.round(data.capitals.expectedCapital)
			}
		},
		setAllianzPolicyEndparticipationAmounts(state, data: CapitalInfo) {
			const index = state.allianzPensionPolicies.findIndex(allianzPolicy => allianzPolicy.id === data.policyId)
			if (index !== -1) {
				state.allianzPensionPolicies[index].endOfParticipationAmounts = data.capitals
			}
		},
		setFundsLoaded(state, value: boolean) {
			state.fundsLoaded = value
		},
		setZeroAlterationAmountsLoaded(state, value: boolean) {
			state.zeroAlterationAmountsLoaded = value
		},
		setEndparticipationAmountsLoaded(state, value: boolean) {
			state.endparticipationAmountsLoaded = value
		},
		setRiskChartAmountsLoaded(state, value: boolean) {
			state.riskChartAmountsLoaded = value
		},
		sumRiskCharts(state, data: {policyId: string, riskProfileShortCode: RiskProfileShortCodes, capitals: AmountGroup}) {

			const policyRiskProfileChartLoaded = state.policyRiskProfileChartLoaded.findIndex(combination =>
				combination.policyId === data.policyId && combination.riskProfileShortCode === data.riskProfileShortCode)

			if (policyRiskProfileChartLoaded === -1) {
				state.riskProfileCharts[data.riskProfileShortCode].pessimistic += data.capitals.pessimisticRealAnnuity
				state.riskProfileCharts[data.riskProfileShortCode].expected += data.capitals.expectedRealAnnuity
				state.riskProfileCharts[data.riskProfileShortCode].optimistic += data.capitals.optimisticRealAnnuity
				state.policyRiskProfileChartLoaded.push({policyId: data.policyId, riskProfileShortCode: data.riskProfileShortCode})
			}
		},
		sumRiskVariableCharts(state, data: {policyId: string, riskProfileShortCode: RiskProfileShortCodes, capitals: AmountGroup}) {

			const policyVariableChartLoaded = state.policyVariableChartLoaded.findIndex(combination =>
				combination.policyId === data.policyId && combination.riskProfileShortCode === data.riskProfileShortCode)

			if (policyVariableChartLoaded === -1) {
				state.riskProfileForVariableCharts[data.riskProfileShortCode].pessimistic += data.capitals.pessimisticRealAnnuity
				state.riskProfileForVariableCharts[data.riskProfileShortCode].expected += data.capitals.expectedRealAnnuity
				state.riskProfileForVariableCharts[data.riskProfileShortCode].optimistic += data.capitals.optimisticRealAnnuity
				state.policyVariableChartLoaded.push({policyId: data.policyId, riskProfileShortCode: data.riskProfileShortCode})
			}
		},
		addIncome(state, income: IncomeItem) {
			state.incomeItems.push(income)
			state.isEdited = true
		},
		setNPRIncomeItems(state, incomeItems: Array<IncomeItem>) {
			const pensionAndStateItems = state.incomeItems.filter(i => i.type === "PENSION" && i.fiscal === "GROSS" || i.type === "STATE_PENSION")

			for (const item of pensionAndStateItems) {
				const index = state.incomeItems.findIndex(i => i.id === item.id)
				if (index !== -1) {
					state.incomeItems.splice(index, 1)
				}
			}

			for (const item of incomeItems) {
				state.incomeItems.push(item)
			}
			state.isEdited = true
		},
		replaceIncome(state, income: IncomeItem) {
			const index = state.incomeItems.findIndex(i => income.id === i.id)
			if (index !== -1) {
				state.incomeItems.splice(index, 1, income)
			} else {
				income.removable = true
				income.editable = true
				state.incomeItems.push(income)
			}
			state.isEdited = true
		},
		removeIncome(state, income: IncomeItem) {
			const index = state.incomeItems.findIndex(i => income.id === i.id)
			if (index !== -1) { state.incomeItems.splice(index, 1) }
			state.isEdited = true
		},
		setAOW(state, data: AOW) {
			state.aow = data
		},
		setTypeOfAOW(state, type: "SINGLE" | "PARTNER" | undefined) {
			state.aow.typeOfAOW = type
		},
		setStatePensionAge(state, age: Age) {
			state.statePensionAge = age
		}
	},
	namespaced: true,
	state: pensiondetails
}

export default pensiondetailsModule
