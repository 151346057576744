import { locale, plural } from "lib/i18n/locale/nl-NL"
import { Options } from "lib/types/i18n"

// tslint:disable:max-line-length

const options: Options = {
	locale,
	plural,
	texts: {
		general: {
			name: "Naam",
			relationNumber: "Relatienummer",
			gender: "Geslacht",
			male: "Man",
			female: "Vrouw",
			perMonth: "per maand",
			netPerMonth: "netto per maand",
			grossPerYear: "bruto per jaar",
			perWeek: "per week",
			hour: "uur",
			save: "Opslaan",
			next: "Verder",
			previous: "Terug",
			yes: "Ja",
			selectedButton: "Geselecteerd",
			selectButton: "Selecteer",
			no: "Nee",
			from: "Vanaf",
			to: "Tot",
			lifelong: "Levenslang",
			capital: "kapitaal",
			loading: "Laden..",
			retrievingData: "Uw gegevens worden opgehaald",
			send: "Verstuur",
			and: "en",
			unknown: "Onbekend",
			skip: "Overslaan",
			httpErrorMessage: "Er is iets mis gegaan, probeer het nogmaals"
		},
		currency: {
			code: "EUR",
			symbol: "€"
		},
		pageHeader: {
			signOut: "uitloggen",
			contact: "Contact"
		},
		pageFooter: {
			copyright: "© Allianz",
			disclaimer: "Disclaimer",
			governance: "Governance",
			privacy: "Privacystatement",
			cookies: "Cookies"
		},
		error: {
			not_xml: "Het geselecteerde bestand is geen xml-bestand.",
			not_valid_xml: "De inhoud en/of structuur van het xml-bestand is onjuist.",
			not_valid_bsn: "Het burgerservicenummer in het bestand is onjuist.",
			outofdate: "De gegevens van het bestand zijn ouder dan 12 maanden. Download de laatste gegevens op mijnpensioenoverzicht.nl",
			olderFile: "De gegevens die u upload zijn ouder dan de gegevens die al bekend zijn."
		},
		downloads: {
			general: {
				title: "Algemene downloads",
				investmentUpdates: "Beleggingsupdates",
				partnerPension: "Afstandsverklaring bijzonder partnerpensioen",
				allianzLifeCycleDownloadDutch: "Allianz Lifecycles Nederlands",
				allianzLifeCycleDownloadEnglish: "Allianz Lifecycles Engels",
				selfInvesting: "Zelf beleggen"
			},
			notRetired: {
				title: "U bouwt nog pensioen op bij Allianz",
				general: "Algemeen",
				questionsAboutUPO: "Vragen over uw UPO",
				questionsAboutAllianzPension: "Vragen over Allianz Pensioen",
				questionsAboutAllianzPPI: "Vragen over Allianz PPI",
				retiringSoon: "U gaat binnenkort met Pensioen"
			},
			retired: {
				title: "U heeft een pensioen- of lijfrenteuitkering bij Allianz",
				general: "Algemeen",
				directIngaandZekerPensioen: "Direct Ingaand Zeker Pensioen",
				directIngaandKeuzePensioen: "Direct Ingaand Keuze Pensioen"
			}
		},
		dossierModal: {
			intro: "Hieronder ziet u een overzicht van uw pensioendocumenten. Mist u een document? Kijk voor een volledig overzicht op ",
			introLink: "Mijn Pensioenkluis."
		},
		profileModal: {
			title: "Mijn gegevens",
			overviewButton: "naar overzicht",
			personalDetails: {
				title: "Persoonlijke gegevens"
			},
			contact: {
				title: "Contactgegevens",
				phoneNumber: "Telefoonnummer",
				address: "Adres",
				addressChangeText: "Adres wijziging (buitenland) <a href='https://www.allianz.nl/particulier/contact/adres-wijzigen.html' target='_blank'>doorgeven</a>.",
				noEmail: "Er is geen e-mailadres bekend",
				details: {
					title: "E-mail gegevens",
					summary: "Klopt uw e-mailadres nog? Controleer of wij het juiste e-mailadres gebruiken. Zo niet, vul dan direct de juiste gegevens in. Zo blijft u digitaal op de hoogte over uw pensioen bij Allianz. Bij voorkeur uw privé e-mailadres zodat wij u nu en in de toekomst zo goed mogelijk op de hoogte kunnen houden.",
					email: "E-mailadres",
					sendDigital: "Ik ontvang informatie over mijn pensioen digitaal.",
					sendMail: "Ik ontvang informatie over mijn pensioen per post.",
					legal: "Communicatievoorkeur"
				},
				pondress: "Wilt u uw e-mailadres en/of communicatievoorkeur wijzigen?",
				pondressButton: "Naar mijn Pensioenkluis"
			},
			edit: {
				changeInfo: "Wilt u uw gegevens wijzigen?",
				change: "AANPASSEN",
				emailAndPhone: "E-mailadres en telefoonnummer",
				back: "Terug",
				save: "Opslaan"
			},
			partnerdetails: {
				title: "Gegevens (ex) partner",
				noPartnerData: "Er is geen partner vanuit de administratie bij ons bekend. Als u het bestand van Mijnpensioenoverzicht.nl inlaadt (of al heeft ingeladen) dan nemen wij daarvan de gegevens over.",
				typeOfAOW: {
					title: "",
					option: {
						1: "Ik heb een partner (AOW-gehuwd wordt getoond in de Planner en bij Overig inkomen)",
						2: "Ik heb geen partner (AOW-ongehuwd wordt getoond in de Planner en bij Overig inkomen)"
					}
				}
			},
			employment: {
				title: "Gegevens dienstverband",
				employer: "Werkgever",
				startDate: "Datum in dienst",
				endDate: "Datum uit dienst",
				fulltimeGrossAnnualSalary: "Bruto jaarsalaris",
				parttimePercentage: "Parttimepercentage",
				policyNumber: "Polisnummer"
			},
			bsn: "BSN",
			maritalStatus: "Burgerlijke staat",
			birthDate: "Geboortedatum"
		},
		menuModal: {
			directly: {
				title: "Direct doen",
				riskprofile: "Risicoprofiel bepalen of wijzigen",
				variablepension: "Beleggingskeuze maken of wijzigen",
				pensiongoal: "Pensioendoel aanpassen",
				otherincome: "Overig inkomen aanvullen",
				valuetransfer: "Offerte waardeoverdracht aanvragen",
				extrasavings: "Bijsparen",
				planner: "Pensioen plannen",
				investments: "Beleggingen bekijken",
				pensionDetails: "Pensioengegevens bekijken"
			},
			moreInformation: {
				title: "Meer informatie",
				whatIf: "Wat gebeurt er bij...?",
				faq: "Veelgestelde vragen",
				advisor: "Vind uw adviseur",
				downloads: "Downloads"
			},
			contact: {
				title: "Contact met Allianz",
				address: {
					to: "Allianz - afdeling Pensioenen",
					address: "Postbus 761",
					zipcode: "3000 AT",
					city: "Rotterdam",
					telephone: "Tel: 088 - 577 4143",
					email: "E-mail: pensioenen@allianz.nl"
				},
				titleQuestions: "Heeft u vragen over uw Allianz pensioenverzekering?",
				ppiProduct: {
					titleQuestions: "Heeft u vragen over uw Allianz PPI pensioen?",
					telephone: "Tel: 088-577 4286",
					email: "E-mail: ppi@allianz.nl"
				},
				isRetired: {
					titleQuestions: "Heeft u vragen over uw Allianz pensioenuitkering?",
					telephone: "Tel: 088-577 6560",
					email: "E-mail: service.leven@allianz.nl"
				},
				reminderProvidePolicy: "Vermeld altijd uw polis- of deelnamenummer in het onderwerp van uw bericht.",
				contactForm: "Contactformulier"
			}
		},
		pensionPolicyDetails: {
			lastPosition: {
				title: "Laatste stand",
				items: {
					date: "Datum",
					change: "Mutatie"
				}
			},
			pension: {
				title: "Pensioen",
				otherTitle: "Polis details",
				items: {
					investPension: "Pensioen uit beleggingen",
					pensionPerYear: "Pensioen per jaar"
				},
				error: "Er is iets misgegaan bij het selecteren van de polis"
			},
			basedInformation: {
				title: "Op welke gegevens is uw pensioen gebaseerd?",
				items: {
					policyNumber: "Polisnummer",
					alterationDate: "Bijgewerkt op",
					startDate: "Start deelname",
					employmentDate: "Datum in dienst",
					retirementDate: "Pensioendatum",
					grossYearSalary: "Bruto jaarsalaris",
					pensionYearSalary: "Pensioengevend jaarsalaris",
					pensionBasis: "Pensioengrondslag",
					parttimePercentage: "Parttimepercentage",
					franchise: "Franchise",
					retiree: {
						policyType: "Soort uitkering",
						frequency: "Wij keren uit per",
						frequencyTypes: {
							YEARLY: "Jaar",
							HALF_YEARLY: "Halfjaar",
							TRIMESTER: "Trimester",
							QUARTERLY: "Kwartaal",
							TWO_MONTHLY: "Twee maanden",
							MONTHLY: "Maand"
						},
						benefit: "Uw uitkering",
						endDate: "Duur of einddatum van deze uitkering",
						lifelong: "Levenslang",
						benefitAfter: "Daarna wordt uw uitkering"
					}
				}
			},
			whatIf: {
				title: "Wat gebeurt er als u nu overlijdt?",
				items: {
					partnerPercentage: "Dan gaat uw pensioen over naar uw partner met",
					partnerAmount: "Als u nu overlijdt krijgt uw partner",
					noPartner: "Deze pensioenuitkering wordt beëindigd en er volgt geen uitkering meer."
				}
			},
			investments: {
				title: "Pensioen uit beleggingen",
				items: {
					investmentValue: "Huidige beleggingswaarde",
					capitalValue: "Kapitaal te bereiken (verwacht)",
					riskProfile: {
						title: "Risicoprofiel"
					},
					investmentProfile: {
						title: "Beleggingsprofiel"
					}
				}
			},
			premium: {
				title: "Premie",
				items: {
					currentPremium: "Huidige beschikbare premie",
					voluntaryPartnerPensionPremium: "Premie partnerpensioen",
					voluntaryPartnerPensionPremiumLinkTitle: "Zie transacties op beleggingen pagina",
					scalePercentage: "Staffelpercentage"
				}
			}
		},
		pensionSummaryModal: {
			title: "Mijn Pensioenoverzicht",
			step1: {
				prefix: "Ga naar",
				suffix: "en log in met uw DigiD of met een Europees erkend inlogmiddel (eIDAS)."
			},
			step2: {
				title: "Download uw gegevens",
				description: "Controleer uw gegevens en klik op 'Bekijk mijn pensioenoverzicht'. Rechtsboven in het scherm (onder uw naam) klikt u op 'Download uw pensioenoverzicht'. Sla het bestand op als XML en ga terug naar dit portaal."
			},
			step3: {
				title: "Selecteer het XML-bestand dat u zojuist heeft opgeslagen en klik op 'Bestand uploaden'.",
				description: "geen bestand geselecteerd",
				uploadButton: "Bestand uploaden"
			},
			partner: {
				title: "Wilt u ook het pensioen van uw partner toevoegen?",
				step1: {
					prefix: "Ga naar",
					suffix: "en laat uw partner inloggen met zijn of haar DigiD."
				},
				step2: {
					title: "Download de gegevens van uw partner",
					description: "Rechtsboven in het scherm (boven de naam van uw partner) kiest u voor ‘Download gegevens of samenvatting’. Sla het bestand op als XML en log uit. Ga terug naar dit portaal."
				},
				step3: {
					title: "Selecteer het XML-bestand dat u zojuist heeft opgeslagen en klik op 'Bestand uploaden'.",
					description: "geen bestand geselecteerd",
					uploadButton: "Bestand uploaden"
				}
			}
		},
		incomeModal: {
			title: "Voeg een inkomen toe",
			myPartner: {
				title: "Mijn partner",
				description: "Uw AOW bedrag is afhankelijk van uw persoonlijke situatie. Leeft u met een partner dan krijgen jullie beide een lager AOW bedrag dan wanneer u zonder partner leeft. U kunt deze instelling wijzigen t.b.v. de situatie.",
				options: {
					partner: "Ik heb een partner",
					noPartner: "Ik heb geen partner"
				}
			},
			datePlaceholder: "dd-mm-jjjj",
			change: "wijzigen",
			fields: {
				incomeType: {
					title: "Type inkomen:",
					choosetype: "Kies type",
					options: {
						ANNUITY: "Lijfrente",
						FISCAL_SAVINGS: "Banksparen",
						PENSION: "Netto pensioen",
						SAVINGS: "Spaargeld",
						INVESTED_CAPITAL: "Vermogen",
						OTHER: "Overig"
					},
					STATE_PENSION: "AOW",
					PENSION: "Pensioen",
					aowNoPartner: "AOW voor ongehuwden",
					aowWithPartner: "AOW voor gehuwden"
				},
				startingAge: {
					title: "Vanaf",
					error: "Het veld moet gevuld zijn met een datum"
				},
				untilAge: {
					title: "Tot",
					option: {
						1: "Levenslang",
						2: "Einddatum"
					},
					error: "Het veld moet gevuld zijn met een datum"
				},
				recievedMoney: {
					title: "Verwachte uitkering",
					descriptionnet: "netto per jaar",
					descriptiongross: "bruto per jaar"
				},
				description: {
					title: "Beschrijving"
				}
			},
			saveButton: {
				save: "Opslaan",
				saveChanges: "Wijzigingen opslaan"
			},
			done: "Het item is toegevoegd aan de lijst"
		},
		valuetransferAdviseModal: {
			title: "Let op!",
			transferablePensions: {
				advise: "Waardeoverdracht is niet in alle gevallen gunstig. Raadpleeg uw financieel adviseur om onderstaand pensioen samen met u te bekijken.",
				columns: {
					provider: "Uitvoerder",
					reference: "Herkenningsnummer",
					value: "Waarde"
				}
			}
		},
		login: {
			title: "Allianz Persoonlijk Pensioen Portaal",
			subtitle: "Het Persoonlijk Pensioen Portaal is dé centrale plaats waar u alles kunt vinden over uw pensioen bij Allianz. U ziet hier hoeveel kapitaal u al heeft opgebouwd en hoeveel pensioen u naar verwachting krijgt als u met pensioen gaat. Ook kunt u uw persoonlijke keuzes aan ons doorgeven. En eenvoudig diverse scenario’s berekenen, zoals eerder met pensioen gaan of extra geld inleggen.<br /><br />Bent u al met pensioen? Dan vindt u hier alle informatie over uw pensioenuitkering.",
			error: {
				sensitiveContract: "U mag deze deelnemer niet inzien, want het betreft een uitgesloten contract."
			},
			digid: {
				title: "Veilig en snel inloggen met DigiD",
				contentNoDigid: "Heeft u nog geen DigiD?",
				contentLink: "Vraag uw DigiD aan.",
				button: "Inloggen",
				failure: "U heeft het inloggen geannuleerd.",
				error: "Er is een fout opgetreden in de communicatie met DigiD. Probeert u het later nogmaals. Indien deze fout blijft aanhouden, kijk dan op de website https://www.digid.nl voor de laatste informatie.",
				unknownUser: {
					message: "De ingevulde combinatie is niet bekend in ons systeem, probeer het nog eens. Uw DigiD-sessie is nog actief. Klik",
					link: "hier", // Separate link, because we need to attach an event handler to the anchor.
					logout: " om uit te loggen."
				},
				contentNoLoginSuccess: "Heeft u een vraag over DigiD? Ga dan naar <a href='https://www.digid.nl/vraag-en-antwoord' target='_blank'>www.digid.nl/vraag-en-antwoord</a>",
				contentSuggestion: "Om u de beste gebruikerservaring te bieden, raden we u aan Safari, Google Chrome of Microsoft Edge te gebruiken. Het Allianz Persoonlijk Pensioen Portaal functioneert nog niet optimaal in andere browsers; het is mogelijk dat u foutmeldingen of waarschuwingen te zien krijgt. We doen ons best om het aantal browsers dat ondersteund wordt uit te breiden.",
				contentSupport: "Heeft u een vraag over DigiD? Ga dan naar",
				contentSupportLink: "www.digid.nl/vraag-en-antwoord"
			},
			eidas: {
				title: "Veilig en snel inloggen met eIDAS EU login",
				button: "Inloggen",
				failure: "U heeft het inloggen geannuleerd.",
				error: "Er is een fout opgetreden in de communicatie met eIDAS. Probeert u het later nogmaals.",
				unknownUser: {
					message: "De ingevulde combinatie is niet bekend in ons systeem, probeer het nog eens. Uw eIDAS-sessie is nog actief. Klik",
					link: "hier", // Separate link, because we need to attach an event handler to the anchor.
					logout: " om uit te loggen."
				},
				content: "Inloggen met een Europees erkend inlogmiddel"
			},
			eu: {
				title: "Inloggen buitenland",
				content: "Woont u in een ander EU-land dan Nederland, log in met het in Europa erkende inlogmiddel eIDAS.",
				button: "Inloggen"
			},
			subContent: "Om u de beste gebruikerservaring te bieden, raden we u aan Google Chrome of Microsoft Edge te gebruiken. Het Allianz Persoonlijk Pensioen Portaal functioneert nog niet optimaal in andere browsers; het is mogelijk dat u foutmeldingen of waarschuwingen te zien krijgt. We doen ons best om het aantal browsers dat ondersteund wordt uit te breiden."
		},
		impersonate: {
			title: "Uw gegevens worden opgehaald; dit kan even duren.",
			subtitle: "Dank voor uw geduld.",
			systemBusy: {
				waitingMessage1: "Het is op dit moment erg druk.",
				waitingMessage2: "We raden u aan het op een ander tijdstip nogmaals te proberen.",
				waitingMessage3: "Excuses voor het eventuele ongemak."
			},
			systemTimeout: {
				title: "De gegevens kunnen niet worden geladen.",
				timeoutMessage: "Waarschijnlijk is de sessie verlopen. Sluit deze tab en probeer het nogmaals. Als deze melding blijft terugkomen, log dan uit in Control Room en log daar opnieuw in. Als dit de melding ook niet oplost, neem dan contact op met de beheerder."
			}
		},
		index: {
			title: "Uw gegevens worden opgehaald; dit kan even duren.",
			subtitle: "Dank voor uw geduld.",
			systemBusy: {
				waitingMessage1: "Het is op dit moment erg druk.",
				waitingMessage2: "We raden u aan het op een ander tijdstip nogmaals te proberen.",
				waitingMessage3: "Excuses voor het eventuele ongemak."
			},
			emailAndCommunicationPreference: {
				title: "Welkom op uw Pensioenportaal",
				content1Nl: "Wij verzoeken u om uw privé e-mailadres op te geven om beter met u te kunnen samenwerken en de benodigde informatie sneller naar u toe kunnen sturen.",
				content2Nl: "Wij zien dat er vaak het e-mailadres van de werkgever is opgenomen. Om u beter te kunnen bereiken willen we graag het privé e-mailadres. Heeft u uw privé e-mailadres doorgegeven? Dank u wel!",
				content1En: "We kindly ask you to provide your private email address so that we can collaborate more effectively and send the necessary information to you more quickly.",
				content2En: "We notice that the employer's email address is often provided. To better reach you, we would prefer your personal email address. Have you shared your personal email address? Thank you!",
				email: "E-mail",
				telephoneNumber: "Telefoonnummer",
				save: "Opslaan",
				communicationPreferenceMessage: "Ik wil informatie over mijn pensioen digitaal ontvangen"
			},
			highlights: {
				10: "Hoe wilt u dat wij voor u beleggen? Bepaal nu uw risicoprofiel!",
				14: "Ga verder met het invullen van uw risicoprofiel.",
				18: "Past uw risicoprofiel nog?",
				20: "Wilt u een vast of variabel pensioen? Maak nu uw beleggingskeuze!",
				24: "Ga verder met het invullen van uw beleggingskeuze.",
				28: "Meer risico nemen met kans op een hoger pensioen? U kunt het nu bepalen!",
				30: "Hoeveel pensioen heeft u nodig voor later? Bepaal nu uw pensioendoel!",
				40: "Hoeveel pensioen ontvangt u straks? Vul uw overig inkomen aan.",
				45: "Klopt uw overig inkomen nog? Download opnieuw de xml.",
				50: "Wilt u pensioen van een vorige werkgever overdragen? Vraag nu waardeoverdracht aan!",
				60: "U heeft een pensioengat. Stel uw pensioendoel bij.",
				65: "Zorg voor voldoende pensioen. Kijk nu wat u zelf kunt doen.",
				66: "Wilt u extra inleggen voor uw pensioen? Dat kan!",
				70: "Wilt u minder gaan werken? Bekijk nu het effect op uw pensioen!",
				80: "Bekijk het rendement van uw beleggingen.",
				90: "Kloppen uw gegevens?",
				100: "Antwoord op veelgestelde vragen.",
				buttonStart: "Start",
				buttonContinue: "Ga verder"
			},
			welcome: {
				goodMorning: "Goedemorgen",
				goodAfternoon: "Goedemiddag",
				goodEvening: "Goedenavond",
				goodNight: "Goedenacht",
				mr: "meneer",
				mrs: "mevrouw",
				checkDetails: "Welkom bij Allianz! Kloppen al uw gegevens? Bekijk het in",
				checkDetailsLink: "Mijn gegevens"
			},
			inbox: {
				title: "Inbox",
				noMessages: "Je hebt nog geen berichten van ons ontvangen.",
				toInbox: "Naar inbox"
			},
			documents: {
				title: "Documenten",
				noDocuments: "Er zijn nog geen digitale documenten beschikbaar.",
				toDocuments: "Naar documenten"
			},
			yourPension: {
				title: "Mijn pensioen",
				pensiongoal: "Pensioendoel",
				pensiongoalDescription: "Pensioen dat u denkt nodig te hebben vanaf uw pensioenleeftijd. Heeft u uw pensioendoel nog niet bepaald? Dan is het uitgangspunt voor uw pensioendoel 70% van uw huidige netto inkomen per maand.",
				pensiongoalButton: "Pensioendoel aanpassen",
				expectedPension: "Verwacht pensioen",
				expectedPensionDescription: "Dit is het verwachte pensioen op uw pensioenleeftijd, inclusief AOW. Heeft u pensioen bij vorige werkgevers opgebouwd en ingevuld? Dan ziet u deze hier ook. Om u te laten zien wat uw pensioen straks waard is hebben we rekening gehouden met de inflatie (zogeheten reeële bedragen). We berekenen uw verwachte pensioen altijd per de 1ste van de maand. Uw beleggingssaldo is altijd actueel.",
				pensionDescription: "Hier ziet u uw bij Allianz afgesloten bruto (totale) pensioenuitkering per jaar. Klik op ‘Naar mijn pensioen’ om de details van uw pensioen in te zien.",
				expectedPensionButton: "Naar mijn pensioen"
			},
			pensionProfile: {
				title: "Mijn pensioenprofiel",
				pensionprofile: "Pensioenprofiel",
				description: "Door uw pensioenprofiel zo volledig mogelijk te vullen, neemt de nauwkeurigheid en betrouwbaarheid van de getoonde bedragen toe. En sluiten de keuzes die u maakt beter aan op uw persoonlijke situatie.",
				riskprofile: "Risicoprofiel",
				variablepension: "Beleggingskeuze",
				pensiongoal: "Pensioendoel",
				otherincome: "Overig inkomen",
				edit: "wijzig",
				button: "Pensioenprofiel aanvullen",
				button100: "Pensioenprofiel aanpassen"
			},
			profile: {
				title: "Mijn gegevens",
				email: "E-mailadres",
				noEmail: "Er is geen e-mailadres bekend",
				sendDigital: "Ik ontvang informatie over mijn pensioen digitaal.",
				sendMail: "Ik ontvang informatie over mijn pensioen per post.",
				button: "Gegevens bekijken",
				findUPO: "Op zoek naar uw <a href='https://allianzpensioenkluis.nl/app/login' target='_blank'>UPO</a>?"

			},
			investments: {
				title: "Mijn beleggingen",
				balance: "Beleggingssaldo",
				button: "Beleggingen bekijken"
			}
		},
		contact: {
			title: "Contact",
			subtitle: "Contactformulier",
			name: "Naam",
			email: "E-mailadres",
			phoneNumber: "Telefoonnummer",
			choosesubject: "Kies onderwerp",
			subjectitems: {
				buyoff: "Afkopen pensioenpolis",
				divorce: "Echtscheiding",
				changedetails: "Gegevens wijzigen",
				complaint: "Klacht",
				retirement: "Met pensioen gaan",
				voluntarycoverage: "Vrijwillige dekking toevoegen",
				valuetransfer: "Waardeoverdracht",
				other: "Overig"
			},
			question: "Vraag / Opmerking",
			send: "Bericht verzenden",
			sent: "Uw bericht is verzonden. U ontvangt binnen vijf werkdagen een reactie van ons.",
			home: "Home",
			consultant: {
				title: "Advies nodig?",
				description: "Neem dan contact op met een adviseur"
			}
		},
		pensiongoal: {
			intro: {
				title: "Hoeveel pensioen heeft u nodig voor later?",
				descriptionFirstTime: "Uitgangspunt is 70% van uw huidige netto inkomen per maand. Maar misschien heeft u meer of minder inkomen nodig na uw pensioendatum. Bepaal nu uw pensioendoel.",
				description: "Bedankt voor het opnieuw bepalen van uw pensioendoel. We gaan in onze berekeningen uit van dit bedrag. Heeft u meer of minder inkomen nodig na uw pensioendatum? Dan kunt u uw pensioendoel altijd opnieuw bepalen.",
				button: "Aan de slag!"
			},
			wizard: {
				question1: {
					title: "Welke manier heeft uw voorkeur?",
					subtitle: "Het uitgangspunt is 70% van uw huidige netto inkomen per maand. Maar misschien heeft u meer of minder inkomen nodig na uw pensioendatum. Bepaal uw pensioendoel aan de hand van de verwachte uitgaven na uw pensioendatum. U kunt daarbij gebruik maken van richtbedragen van een vergelijkbaar huishouden vastgesteld door het Nibud. Of pas uw pensioendoel aan door een indicatieve schatting te doen.",
					optionA: {
						title: "Optie 1",
						description: "Ik maak gebruik van richtbedragen van een vergelijkbaar huishouden vastgesteld door het Nibud."
					},
					optionB: {
						title: "Optie 2",
						description: "Ik doe zelf een indicatieve schatting."
					}
				},
				question2: {
					title: "Hoeveel pensioen heeft u nodig?",
					subtitle: "test2"
				},
				outro: {
					title: "Bedankt",
					subtitle: "Bedankt voor het bepalen van uw pensioendoel. Daarmee heeft u uw pensioenprofiel verder aangevuld. We gaan in onze berekeningen uit van dit bedrag. Heeft u meer of minder inkomen nodig na uw pensioendatum? Dan kunt u uw pensioendoel altijd opnieuw bepalen.",
					call2action: {
						title: "Vul nu uw pensioenprofiel verder aan!",
						content: "U heeft zojuist uw pensioendoel bepaald, maar heeft u ook overige inkomsten? Zorg er voor dat uw beleggingen nog beter bij uw persoonlijke situatie passen. Vul daarom uw pensioenprofiel verder aan en wij beleggen uw pensioenpremie nog meer op maat voor u.",
						button: "Pensioenprofiel aanvullen",
						quitButton: "Home"
					}
				}
			},
			general: {
				title: "Hoeveel pensioen heeft u nodig?",
				content: "Het uitgangspunt is 70% van uw huidige netto inkomen per maand. Maar misschien heeft u meer of minder inkomen nodig na uw pensioendatum. Weet u niet hoeveel inkomen u nodig heeft? Wilt u hulp bij het bepalen van het benodigde inkomen?",
				contentLink: "Bepaal uw pensioendoel",
				content2: "met behulp van richtbedragen van een vergelijkbaar huishouden, vastgesteld door het Nibud.",
				subtext: "Ik schat in dat ik vanaf mijn pensioendatum:",
				subtext2: "netto per maand nodig heb.",
				help: "Hulp bij het maken van een inschatting"
			},
			form: {
				title: "Pensioendoel aanpassen",
				content: "Of u later genoeg pensioen krijgt, is afhankelijk van hoeveel pensioen u nodigt heeft. U maakt daaarom een inschatting van uw uitgaven als u met pensioen bent. U kunt daarbij gebruik maken van richtbedragen van een vergelijkbaar huishouden, vastgesteld door het Nibud.",
				selectAverage: "Neem gemiddelden over",
				emptyAmounts: "Velden leegmaken",
				average: "Richtbedrag Nibud",
				amount: "Bedrag",
				estimation: "Totaal benodigd pensioen netto per maand",
				validation: "Het maximum per bedrag is",
				annuleren: "Annuleren",
				submit: "Bevestig uw pensioendoel",
				edit: "Pensioendoel aanpassen"
			},
			outro: {
				title: "Uw pensioendoel is bepaald",
				content: "Bedankt voor het invullen van uw pensioendoel. Dit bedrag ziet u terug op dit portaal en kunt u altijd weer aanpassen.",
				button: "Terug naar mijn pensioen"
			},
			items: {
				housing: "Wonen",
				transportation: "Vervoer",
				food: "Eten en drinken",
				clothing: "Winkelen",
				leisure: "Vrije tijd",
				healthcareEducation: "Zorg",
				other: "Overig"
			}
		},
		pensionExpected: {
			title: "Uw verwachte pensioen",
			form: {
				edit: "Overig inkomen aanvullen",
				upload: "Upload pensioenoverzicht",
				manually: "Handmatig invoeren"
			}
		},
		riskprofile: {
			DEF: "defensief",
			NEU: "neutraal",
			OFF: "offensief",
			notapplicable: "Niet van toepassing",
			intro: {
				notStarted: {
					title: "Hoe wilt u dat wij voor u beleggen?",
					content: "Beleggen kan niet zonder risico. Maar hoeveel risico u neemt, dat bepaalt u zelf. Doe de test in 5 stappen en ontdek uw risicoprofiel.",
					button: "Start de test"
				},
				notFinished: {
					title: "Hoe wilt u dat wij voor u beleggen?",
					content: "Beleggen kan niet zonder risico. Maar hoeveel risico u neemt, dat bepaalt u zelf. U bent al eerder gestart met de test. Rond de stappen af en ontdek uw risicoprofiel.",
					button: "Vervolg de test"
				},
				finished: {
					title: "Past uw risicoprofiel nog?",
					content: "U heeft op {{dateFinished | shortDate}} uw risicoprofiel bepaald. Het is belangrijk om jaarlijks te toetsen of uw risicoprofiel nog steeds aansluit bij uw persoonlijke situatie. Doe de test opnieuw en ontdek of uw risicoprofiel nog bij u past.",
					button: "Start de test"
				}
			},
			questions: {
				livingSituation: {
					title: "Stap 1. Leefsituatie",
					subtitle: "Om voor u vast te stellen welk risico bij u past hebben we een paar vragen over uw leefsituatie.",
					hasPartner: "Heeft u een partner?",
					partnerSaves: "Bouwt uw partner pensioen op via een werkgever?",
					earnsMore: "Wie verdient het meest?",
					me: "Ik verdien meer dan mijn partner.",
					equal: "We verdienen ongeveer even veel.",
					partner: "Mijn partner verdient meer dan ik.",
					rentsHouse: "Heeft u een koopwoning?",
					ownhouse: "Ja",
					rental: "Nee",
					mortgageDone: "Is uw hypotheek op uw pensioendatum bijna of helemaal afgelost?"
				},
				income: {
					title: "Stap 2. Inkomen",
					pensiongoal: {
						question: "Hoeveel inkomen heeft u nodig na uw pensioendatum?",
						content: "Het uitgangspunt is 70% van uw huidige netto inkomen per maand. Maar misschien heeft u meer of minder inkomen nodig na uw pensioendatum. Pas het bedrag zo nodig aan.",
						content2: "Weet u niet hoeveel inkomen u nodig heeft? Wilt u hulp bij het bepalen van het benodigde inkomen?",
						content3: "met behulp van richtbedragen van een vergelijkbaar huishouden, vastgesteld door het Nibud.",
						percentageContent: "Dit is ongeveer",
						percentageContent2: "van uw huidige netto inkomen per maand.",
						pensiongoalPerMonth: "Pensioendoel per maand"
					},
					otherIncome: {
						question: "Heeft u ander inkomen na uw pensioendatum?",
						intro: "Meerdere antwoorden mogelijk",
						options: {
							otherEmployers: "Pensioen bij vorige werkgevers",
							annuity: "Lijfrenteverzekering",
							savings: "Spaargeld of ander vermogen",
							inheritance: "Mogelijke erfenis",
							other: "Ander inkomen",
							no: "Nee, geen ander inkomen"
						}
					}
				},
				investmentRisk: {
					title: "Stap 3. Beleggingsrisico",
					subtitle: "Allianz heeft de wettelijke zorgplicht om vast te stellen dat de beleggingen bij u passen. Om dat te kunnen bepalen hebben we uw houding ten opzichte van risico's nodig.",
					preferredRisk: {
						question: "Wat vindt u van beleggingsrisico?",
						norisk: "Ik houd niet van risico.",
						somerisk: "Ik durf wel enig risico te nemen. Ik schrik er niet van als de waarde van mijn beleggingen schommelt.",
						knownwithrisk: "Ik weet wat beleggen is. Ik ken de risico’s die daarbij horen."
					},
					financialRisk: {
						question: "Welke uitspraak past het beste bij u?",
						nouncertainty: "Ik houd niet van onzekerheid. En ik wil niet het risico lopen dat mijn pensioen minder wordt.",
						minorriskok: "Een beetje risico is niet erg. Zolang het maar beperkt blijft.",
						someriskok: "Ik kan best wel tegen wat risico. Ik weet dat mijn pensioen hierdoor hoger of lager kan uitvallen.",
						thinkaboutrisk: "Ik neem graag weloverwogen risico. Het levert mij waarschijnlijk meer pensioen op, maar er is ook kans dat mijn pensioen lager uitvalt."
					},
					profilePicked: {
						question: "Welke optie past het beste bij u?",
						intro: `Hieronder ziet u 3 situaties voor uw pensioen na uw keuze. Het gaat om keuze a,b en c. De beleggingsopbrengsten van A, B en C verschillen per regeling. Dat komt omdat er in elk van de 3 regelingen een lager (defensief), gemiddeld (neutraal) of hoger (offensief) risico zit.
						Hoe hoger het risico dat u neemt, hoe hoger de verwachte opbrengst. Dat ziet u in de 3 pijlen: de blauwe pijl in het midden is het gemiddelde, de pijl links is als het slechter gaat dan gemiddeld. De pijl rechts als het beter gaat dan gemiddeld.
						Al deze bedragen zijn bruto per maand. En bieden geen garanties voor een toekomstige uitkomst. Maar geven u wel een goed idee wat uw keuze betekent. Kies uit A, B of C de optie die het best bij u past:`,
						optionA: "Defensief",
						optionB: "Neutraal",
						optionC: "Offensief",
						selected: "Uw keuze",
						notSelected: "Selecteer"
					},
					button: "Verder"
				},
				confirmation: {
					title: "Stap 4. Risicoprofiel",
					content: "Op basis van uw antwoorden op de vragen in stap 1, 2 en 3 hebben wij uw risicoprofiel berekend.",
					DEF: "Het defensieve risicoprofiel past bij u als u zo min mogelijk risico kunt of wilt nemen met uw pensioen. Bijvoorbeeld omdat u grotendeels afhankelijk bent van dit pensioen na uw pensioendatum. Of omdat u het niet prettig vindt dat de waarde van uw beleggingen flink kan schommelen.<br><br>Als u dit defensieve risicoprofiel bevestigt, dan beleggen wij vanaf dat moment uw pensioenpremie vooral in veilige beleggingen (zoals obligaties) en minder in risicovolle beleggingen (zoals aandelen). Omdat u met het defensieve risicoprofiel weinig risico neemt, zal het verwachte rendement lager zijn dan bij een neutraal of offensief risicoprofiel. Daar staat tegenover dat het rendement van het defensieve risicoprofiel naar verwachting minder zal schommelen dan dat van het neutrale en offensieve risicoprofiel.",
					NEU: "Het neutrale risicoprofiel past bij u als u enig risico kunt of wilt nemen met uw pensioen. Bijvoorbeeld omdat u naast dit pensioen nog andere bronnen van inkomen heeft na uw pensioendatum. U realiseert zich dat het nemen van enig risico nodig is om kans te maken op een hoger rendement. U schrikt er niet van als de waarde van uw beleggingen tussentijds schommelt, want u weet dat u op lange termijn aan het beleggen bent.<br><br>Als u dit neutrale risicoprofiel bevestigt, dan beleggen wij vanaf dat moment uw pensioenpremie voor een belangrijk deel in risicovolle beleggingen (zoals aandelen). Daarnaast beleggen we in veilige beleggingen (zoals obligaties). Omdat u met het neutrale risicoprofiel enig risico neemt, zal het verwachte rendement hoger zijn dan bij een defensief risicoprofiel, maar lager dan bij een offensief risicoprofiel. Het rendement van het neutrale risicoprofiel zal naar verwachting meer schommelen dan dat van het defensieve profiel, maar minder dan dat van het offensieve risicoprofiel.",
					OFF: "Het offensieve risicoprofiel past bij u als u risico kunt of wilt nemen met uw pensioen. Bijvoorbeeld omdat u naast dit pensioen nog voldoende andere bronnen van inkomen heeft na uw pensioendatum. U bent bereid meer risico te nemen, omdat u zich realiseert dat het nemen van risico nodig is om kans te maken op een hoger rendement. U schrikt er niet van als de waarde van uw beleggingen tussentijds schommelt, want u weet dat u op lange termijn aan het beleggen bent. U realiseert zich ook dat uw pensioen lager kan zijn als de beleggingsresultaten tegen vallen.<br><br>Als u dit offensieve risicoprofiel bevestigt, dan beleggen wij vanaf dat moment uw pensioenpremie vooral in risicovolle beleggingen (zoals aandelen). Omdat u met het offensieve risicoprofiel meer risico neemt, zal het verwachte rendement hoger zijn dan bij een neutraal of defensief risicoprofiel. Daar staat tegenover dat het rendement van het offensieve risicoprofiel naar verwachting meer zal schommelen dan dat van het neutrale en defensieve risicoprofiel.",
					modal: {
						header: "Weet u het zeker?",
						title: "Weet u zeker dat u een ander risicoprofiel wilt kiezen?",
						content: "U kunt alleen een risicoprofiel kiezen als uw antwoorden overeenkomen met dit profiel. Om uw profiel opnieuw te bepalen moet u de vragenlijst nog een keer doorlopen. Wilt u dit doen of wilt u uw risicoprofiel alsnog opslaan?",
						restartButton: "Ja, opnieuw bepalen",
						confirmButton: "Nee, profiel bevestigen"
					}
				}
			},
			confirm: {
				title: "Stap 5. Bevestig uw risicoprofiel",
				subtitle: "Herkent u zich in dit risicoprofiel?",
				submitFirstTime: "Herkent u zich in dit risicoprofiel? Klik op ‘Risicoprofiel bevestigen’. Wij gaan uw pensioenpremie beleggen in {{lifecycleNew}}.",
				submitNextTime: "Herkent u zich in dit risicoprofiel? Klik op 'Risicoprofiel bevestigen'.",
				submitSame: "Uw risicoprofiel komt overeen met uw huidige beleggingen. We beleggen al volgens dit risicoprofiel. Herkent u zich in dit risicoprofiel? Klik op 'Risicoprofiel bevestigen'.",
				restart: "Herkent u zich niet in dit risicoprofiel? Doorloop de test dan nog een keer en",
				restartButton: "bepaal opnieuw uw risicoprofiel",
				confirmButton: "risicoprofiel bevestigen"
			},
			outro: {
				title: "Bedankt",
				contentFirstTime: "Bedankt voor het afronden van de test. U heeft nu uw risicoprofiel bepaald en daarmee uw pensioenprofiel verder aangevuld. Wij gaan uw pensioenpremie beleggen in {{lifecycleNew}}. Binnenkort ziet u dit terug in 'Mijn beleggingen'.",
				contentNextTime: "Bedankt voor het afronden van de test. U heeft uw risicoprofiel opnieuw bepaald. Wij gaan uw beleggingen aanpassen van {{lifecycleOld}} naar {{lifecycleNew}}. Binnenkort ziet u dit terug in 'Mijn beleggingen'.",
				contentSame: "Bedankt voor het afronden van de test. U heeft uw risicoprofiel opnieuw bepaald. Wij blijven uw pensioenpremie beleggen in {{lifecycleNew}}. U ziet dit terug in 'Mijn beleggingen'.",
				call2action: {
					title: "Vul nu uw pensioenprofiel verder aan!",
					content: "U heeft zojuist uw risicoprofiel bepaald, maar wat is uw beleggingskeuze? Zorg er voor dat uw beleggingen nog beter bij uw persoonlijke situatie passen. Vul daarom uw pensioenprofiel verder aan en wij beleggen uw pensioenpremie nog meer op maat voor u.",
					contentlessthen10risprofiles: "U heeft zojuist uw risicoprofiel bepaald, maar wat is uw pensioendoel? Hoeveel denkt u nodig te hebben na uw pensioendatum? Zorg er voor dat uw beleggingen nog beter bij uw persoonlijke situatie passen. Vul uw pensioenprofiel verder aan en wij beleggen uw pensioenpremie nog meer op maat voor u.",
					button: "Pensioenprofiel aanvullen",
					quitButton: "Home"
				}
			}
		},
		variablepension: {
			lifecycles: {
				details: {
					part1: "Als u straks met pensioen gaat, dan kunt u kiezen tussen een vaste pensioenuitkering of een variabele pensioenuitkering. Een variabele pensioenuitkering wordt ook wel doorbeleggen genoemd. Bij Allianz kunt u kiezen voor gedeeltelijk doorbeleggen.",
					part2: "De keuze tussen een vaste of variabele pensioenuitkering hoeft u pas op uw pensioendatum te maken. Het is echter belangrijk om nu alvast te weten wat uw voorkeur heeft. Wij stemmen uw beleggingen er namelijk op af en kunnen daarmee optimaal voorsorteren op uw keuze.",
					part3: "Het verloop van deze lifecycle ziet u hieronder:",
					part4: "U kunt uw beleggingskeuze trouwens op ieder moment voor uw pensioendatum wijzigen. Dus als u nog van gedachten verandert, dan is dat geen probleem.",
					VAST_100: {
						title: "100% vast",
						content: "Als u kiest voor 100% vast dan betekent dit dat wij er rekening mee houden dat u op uw pensioendatum kiest voor een 100% vaste pensioenuitkering. Dit betekent dat wij minder risicovol beleggen dan wanneer u voor een variabele pensioenuitkering kiest. Wij beleggen uw pensioenpremie in {{lifecycleInfo | locale}}.",
						details: "U heeft de combinatie 100% vast en 0% variabel geselecteerd."
					},
					VAST_75: {
						title: "75% vast en 25% variabel",
						content: "Als u kiest voor 75% vast en 25% variabel dan betekent dit dat wij er rekening mee houden dat u op uw pensioendatum kiest voor een pensioenuitkering die 75% vast is en 25% variabel. Wij beleggen uw pensioenpremie in {{lifecycleInfo | locale}}. Dit betekent dat wij met iets meer risico beleggen dan wanneer u voor een 100% vaste pensioenuitkering kiest.",
						details: "U heeft de combinatie 75% vast en 25% variabel geselecteerd."
					},
					VAST_50: {
						title: "50% vast en 50% variabel",
						content: "Als u kiest voor 50% vast en 50% variabel dan betekent dit dat wij er rekening mee houden dat u waarschijnlijk op uw pensioendatum kiest voor een pensioenuitkering die 50% vast is en 50% variabel. Wij beleggen uw pensioenpremie in {{lifecycleInfo | locale}}. Dit betekent dat wij met iets meer risico beleggen dan wanneer u voor een 100% vaste pensioenuitkering kiest.",
						details: "U heeft de combinatie 50% vast en 50% variabel geselecteerd."
					},
					VAST_25: {
						title: "25% vast en 75% variabel",
						content: "Als u kiest voor 25% vast en 75% variabel dan betekent dit dat wij er rekening mee houden dat u waarschijnlijk op uw pensioendatum kiest voor een pensioenuitkering die 25% vast is en 75% variabel. Wij beleggen uw pensioenpremie in Allianz Lifecycle Offensief (75% doorbeleggen). Dit betekent dat wij met iets meer risico beleggen dan wanneer u voor een 100% vaste pensioenuitkering kiest.",
						details: "U heeft de combinatie 25% vast en 75% variabel geselecteerd."
					},
					VAST_0: {
						title: "100% variabel",
						content: "Als u kiest voor 100% variabel dan betekent dit dat wij er rekening mee houden dat u op uw pensioendatum kiest voor een 100% variabele pensioenuitkering. Wij beleggen uw pensioenpremie in Allianz Lifecycle Doorbeleggen. Dit betekent dat wij met meer risico beleggen dan wanneer u voor een vaste pensioenuitkering kiest.",
						details: "U heeft de combinatie 0% vast en 100% variabel geselecteerd."
					}
				},
				DEF: {
					VAST_100: "Allianz Lifecycle Defensief",
					VAST_75: "Allianz Lifecycle Defensief 25% Doorbeleggen"
				},
				NEU: {
					VAST_100: "Allianz Lifecycle Neutraal",
					VAST_75: "Allianz Lifecycle Neutraal 25% Doorbeleggen",
					VAST_50: "Allianz Lifecycle Neutraal 50% Doorbeleggen"
				},
				OFF: {
					VAST_100: "Allianz Lifecycle Offensief",
					VAST_75: "Allianz Lifecycle Offensief 25% Doorbeleggen",
					VAST_50: "Allianz Lifecycle Offensief 50% Doorbeleggen",
					VAST_25: "Allianz Lifecycle Offensief 75% Doorbeleggen",
					VAST_0: "Allianz Lifecycle Offensief 100% Doorbeleggen"
				}
			},
			intro: {
				title: "Wilt u een vast of variabel pensioen?",
				contentFirstTime: "Eerder heeft u uw risicoprofiel bepaald. U heeft een {{riskprofile | locale}} risicoprofiel. Wij beleggen uw pensioenpremie daarom in {{lifecycleDefault | locale}}. Maar wat is uw beleggingskeuze; wilt u straks een vast of een variabel pensioen? Maak nu uw beleggingskeuze en wij beleggen uw pensioenpremie nog meer op maat voor u.",
				contentNotFinished: "Eerder heeft u uw risicoprofiel bepaald. U heeft een {{riskprofile | locale}} risicoprofiel. Wij beleggen uw pensioenpremie daarom in {{lifecycleDefault | locale}}. Maar wat is uw beleggingskeuze; wilt u straks een vast of een variabel pensioen? U bent al eerder gestart, maar hebt de vragen niet afgerond. Maak nu uw beleggingskeuze en wij beleggen uw pensioenpremie nog meer op maat voor u.",
				contentFinished: "U heeft op {{dateFinished | shortDate}} uw beleggingskeuze gemaakt. Sluit deze keuze nog steeds aan bij uw persoonlijke situatie? Maak opnieuw uw beleggingskeuze en wij beleggen uw pensioenpremie nog meer op maat voor u.",
				contentNoPofileCheck: "Voordat u uw beleggingskeuze kunt maken, moet u eerst uw risicoprofiel vast stellen.",
				button: "Beleggingskeuze maken",
				buttonNoPofileCheck: "Naar risicoprofiel"
			},
			question1: {
				title: "Vast of variabel pensioen",
				question: "Welke pensioenuitkering heeft uw voorkeur?",
				intro: "Bij een vaste pensioenuitkering staat de hoogte van de uitkering vooraf vast. Dit geeft u zekerheid. Bij een variabele pensioenuitkering staat de hoogte van de uitkering niet vast. Deze kan stijgen, maar ook dalen. Welke pensioenuitkering heeft uw voorkeur?",
				optionA: {
					title: "Een vaste pensioenuitkering",
					info: "Bij een vast pensioen staat de hoogte van uw uitkering vooraf vast. Dit geeft u zekerheid. De hoogte van uw uitkering is sterk afhankelijk van de marktrente. Staat de marktrente laag op het moment dat u de uitkering aankoopt? Dan krijgt u een lagere uitkering. De marktrente staat al enkele jaren erg laag. Hierdoor valt de vaste pensioenuitkering op dit moment voor veel mensen tegen."
				},
				optionB: {
					title: "Een variabele pensioenuitkering",
					info: "Bij een variabele pensioenuitkerings staat de hoogte van de uitkering niet vast. U belegt door met (een deel van) uw pensioengeld. Hierdoor is uw uitkering minder afhankelijk van de marktrente op het moment dat u de uitkering aankoopt. En u kunt voordeel hebben van de resultaten van beleggingen. Maar de resultaten kunnen ook tegenvallen. Dit betekent dus ook dat uw uitkering kan dalen."
				}
			},
			question2: {
				title: "Variabel pensioen",
				content: "Uw voorkeur gaat uit naar een variabele pensioenuitkering. Wij hebben diverse combinaties van een vaste en variabele pensioenuitkering. De combinaties verschillen in verwachte opbrengst (donker blauwe pijl in het midden), opbrengst in een negatief scenario (licht blauwe pijl links) en opbrengst in een positief scenario (licht blauwe pijl rechts). De getoonde bedragen zijn bruto pensioen per maand. Kies hieronder de combinatie die het beste bij u past.",
				OFF: {
					VAST_100: "100% vast",
					VAST_75: "75% vast / 25% variabel",
					VAST_50: "50% vast / 50% variabel",
					VAST_25: "25% vast / 75% variabel",
					VAST_0: "100% variabel"
				},
				NEU: {
					VAST_100: "100% vast",
					VAST_75: "75% vast / 25% variabel",
					VAST_50: "50% vast / 50% variabel"
				},
				DEF: {
					VAST_100: "100% vast",
					VAST_75: "75% vast / 25% variabel"
				}
			},
			confirmation: {
				save: "Beleggingskeuze bevestigen",
				fixedTitle: "Vast pensioen",
				variableTitle: "Variabel pensioen",
				content: {
					VAST_100: "Uw voorkeur gaat uit naar een vaste pensioenuitkering.",
					VAST_75: "U heeft gekozen voor de combinatie 75% vast en 25% variabel. Dit betekent dat u voor het grootste deel voorsorteert op een vaste pensioenuitkering en voor een beperkt deel op een variabele pensioenuitkering (doorbeleggen).",
					VAST_50: "U heeft gekozen voor de combinatie 50% vast en 50% variabel. Dit betekent dat u voor de helft voorsorteert op een vaste pensioenuitkering en voor de helft op een variabele pensioenuitkering (doorbeleggen).",
					VAST_25: "U heeft gekozen voor de combinatie 25% vast en 75% variabel. Dit betekent dat u voor een beperkt deel voorsorteert op een vaste pensioenuitkering en voor het grootste deel op een variabele pensioenuitkering (doorbeleggen).",
					VAST_0: "U heeft gekozen voor 100% variabel. Dit betekent dat u volledig voorsorteert op een variabele pensioenuitkering (doorbeleggen)."
				},
				submitText: "Klik op ‘Beleggingskeuze bevestigen’ om uw keuze op te slaan."
			},
			outro: {
				title: "Bedankt",
				contentFirstTime: "Bedankt voor het bevestigen van uw beleggingskeuze. Daarmee heeft u uw pensioenprofiel verder aangevuld. Wij gaan uw pensioenpremie beleggen in {{lifecycleNew | locale}}. Binnenkort ziet u dit terug in 'Mijn beleggingen'.",
				contentNextTime: "Bedankt voor het opnieuw bevestigen van uw beleggingskeuze. Wij gaan uw beleggingen aanpassen van {{lifecycleOld}} naar {{lifecycleNew | locale}}. Binnenkort ziet u dit terug in 'Mijn beleggingen'.",
				contentSame: "Bedankt voor het bevestigen van uw beleggingskeuze. Wij blijven uw pensioenpremie beleggen in {{lifecycleNew | locale}}. U ziet dit terug in 'Mijn beleggingen'.",
				call2action: {
					title: "Vul nu uw pensioenprofiel verder aan!",
					content: "U heeft zojuist uw beleggingskeuze bevestigd, maar wat is uw pensioendoel? Hoeveel denkt u nodig te hebben na uw pensioendatum? Vul uw pensioenprofiel verder aan en wij beleggen uw pensioenpremie nog meer op maat voor u.",
					button: "Pensioenprofiel aanvullen",
					quitButton: "Home"
				}
			}
		},
		myinvestments: {
			title: "Mijn beleggingen",
			subtitle: "Huidig beleggingssaldo:",
			content: `In de koersgrafiek ziet u de beleggingswaarde per de laatste van die maand. Als de maand nog niet is afgelopen, ziet u in de laatste balk de huidige waarde.<br/><br/>Wilt u meer informatie over uw beleggingen, neem dan ook een kijkje op <a href='https://www.allianz.nl/zakelijk/pensioen/beleggen.html' target='_blank'>allianz.nl</a>.`,
			retireeContent: `In de grafiek ziet u de beleggingswaarde aan het einde van iedere maand. Als de maand nog niet is afgelopen, ziet u in de laatste balk de huidige waarde.<br><br>

Aan de rechterkant ziet u alle transacties die zijn toegevoegd en onttrokken aan de beleggingswaarde.<br><br>

Bij aanvang ziet u de volgende transacties:<br>
- De door ons ontvangen koopsom.<br>
- Incidentele kosten. Dit zijn de eenmalige administratiekosten.<br><br>

Vervolgens ziet u maandelijks de volgende transacties:<br>
- Doorlopende kosten. Dit zijn de poliskosten.<br>
- Fondsbeheerkosten.<br>
- Overlijdensrisico. Dit is de risicobonus.<br>
- Lijfrente. Dit is uw pensioenuitkering.<br><br>

Als uw pensioen niet per maand wordt uitgekeerd, ziet u de lijfrente een keer in de 3 maanden, 1 of 2 keer per jaar.<br><br>

Meer weten? Ga dan naar de veelgestelde vragen <a href="https://www.allianz.nl/particulier/producten/pensioenverzekeringen/direct-ingaand-keuze-pensioen.html" target="_blank">op onze website</a>.`,
			transactions: "Transacties per",
			transactionsTotal: "Totaalbedrag transacties",
			selectPolicy: "Selecteer polis:",
			description: "Omschrijving",
			amount: "Bedrag",
			investmentFund: "Beleggingsfonds",
			units: "Units",
			rate: "Koers",
			value: "Waarde",
			total: "Totaal",
			transactionFields: {
				ACCIDENT: "Uitkering ongeval",
				ANNUITY: "Lijfrente",
				CANCELATION: "Royement",
				CLAIM_CORRECTION: "Claimcorrectie",
				CLICK: "Click",
				CONVERSION:	"Conversie",
				CRITICAL_ILLNESS: "Uitkering critical illness",
				DEATH: "Overlijden",
				DEATH_BENEFIT_RISK_PREMIUM:	"Overlijdensrisico",
				DISABILITY_CAPITAL:	"Uitkering AO kapitaal",
				DISCOUNT: "Korting",
				DISMEMBERMENT: "Amputatie",
				DIVIDEND: "Dividend",
				ENTRY_AND_EXIT_FEES: "Instap/Uitstapvergoeding",
				EQUALIZE_PENSION_RIGHTS: "Verevenen ouderdomspensioen",
				EXPIRATION:	"Expiratie",
				FUND_MANAGEMENT_FEE: "Fondsbeheerkosten",
				INCIDENTAL_COMMISSION: "Incidentele provisie",
				INCIDENTAL_EXPENSES: "Incidentele kosten",
				INITIAL_COMMISSION:	"Afsluitprovisie",
				INITIAL_EXPENSES: "Eerste kosten",
				INITIAL_RESERVE: "Invoerreserve",
				INTEREST_RATE_REBATE: "Rentestandkorting",
				INTERNAL_VALUE_TRANSFER: "Interne overboeking",
				LOAN: "Belening",
				PARTIAL_APPLICATION: "Gedeeltelijke aanwending",
				PARTIAL_SURRENDER: "Gedeeltelijke afkoop",
				PREMIUM_TAX: "Premiebelasting",
				PROFIT:	"Winstdeling",
				REGULAR_PREMIUM: "Reguliere premie",
				RENEWAL_COMMISSION:	"Doorlopende provisie",
				RENEWAL_EXPENSES: "Doorlopende kosten",
				SERVICE_COST: "Servicekosten",
				SINGLE_PAYMENT:	"Eenmalige betaling",
				SINGLE_PREMIUM:	"Koopsom",
				SPLIT_EX_PARTNER_PENSION: "Afsplitsen bijzonder partnerpensioen",
				SUPPLEMENTARY_RISK_PREMIUM:	"Aanvullende risicopremie",
				SURRENDER: "Afkoop",
				SWITCH:	"Switch",
				SWITCH_IN_RISK_PROFILE:	"Switch in risico profiel",
				TERMINAL_ILLNESS: "Terminale ziekte",
				TRANSACTION_EXPENSES: "Transactiekosten",
				UNIT_BUYING_CHARGE: "Aankoopkosten",
				UNIT_SELLING_CHARGE: "Verkoopkosten",
				UWP_GUARANTEE: "UWP-Garantie",
				VALUE_CORRECTION: "Waardecorrectie",
				YIELD: "Rekenrendement"
			}
		},
		whathappensafter: {
			title: "Wat gebeurt er bij...?",
			gross: "bruto per jaar",
			disabilitylabel: "Arbeidsongeschiktheid",
			disabilityWGA: "U ontvangt een WGA-hiaat van",
			disabilityWIA: "U ontvangt een WIA-excedent van",
			leavelabel: "Uitdiensttreding",
			leavetitle: "U ontvangt",
			diseaselabel: "Uw overlijden",
			diseasepartner: "Uw partner ontvangt",
			diseasechildren: "Uw kinderen ontvangen",
			disabilityWGAExplanation: "Dit is het inkomen dat u elk jaar van ons krijgt als u 100% arbeidsongeschikt bent. We betalen dit tot aan de einddatum van de verzekering. Let op! Hier moet u nog belasting over betalen.",
			disabilityWIAExplanation: "Dit is het geschatte pensioenbedrag dat u van ons krijgt als u 100% arbeidsongeschikt bent. We betalen dit tot aan de einddatum van de verzekering. Let op! Hier moet u nog belasting over betalen.",
			leaveExplanation: "Dit is het geschatte pensioenbedrag per jaar dat u nu al heeft opgebouwd. Let op! Hier moet u nog belasting over betalen.",
			diseasepartnerExplanation: "Dit is het bedrag dat uw partner levenslang elk jaar van ons krijgt als u dood gaat. Let op! Hier moet uw partner nog belasting over betalen.",
			diseasechildrenExplanation: "Voor meer informatie over de maximale leeftijd tot wanneer uw kinderen recht hebben op wezenpensioen, verwijzen wij u naar het pensioenreglement."
		},
		faq: {
			title: "Veelgestelde vragen",
			generalQuestions: "Algemene vragen",
			divorceQuestion: "Wat gebeurt er als u en uw partner uit elkaar gaan?",
			pensioenkluis: "Vragen over de Pensioenkluis",
			informChangeAddress: "Adreswijziging (buitenland) doorgeven",
			upo: "Vragen over het UPO",
			disease: "Hoe kan ik een overlijden bij Allianz melden?",
			prognosis: "Vragen over prognosekapitaal",
			agreement: "Vragen over het Pensioenakkoord?",
			surrender: "Vragen over afkoop van uw pensioenpolis",
			mpo: "Vragen over MijnPensioenOverzicht (MPO)",
			notRetired: "U bouwt nog pensioen op bij Allianz",
			investments: "Vragen over beleggingen",
			valuetransfer: "Vragen over waardeoverdracht",
			retirementOptions: "Vragen over uw mogelijkheden als u met pensioen gaat",
			ppi: "Vragen over Allianz PPI",
			partner: "Vragen over toevoegen van uw partner",
			extraSavings: "Vragen over bijsparen",
			retired: "U heeft een pensioen- of lijfrenteuitkering bij Allianz",
			futurePensionAct: "Wet toekomst pensioenen",
			futurePensionActQuestion: "Wat betekent Wet toekomst pensioenen voor mij?",
			futurePensionActAnswer: "De gevolgen van de Wet toekomst pensioenen spreekt u af met uw werkgever. Per 1 januari 2024 passen we de toetredingsleeftijd aan naar 18 jaar.",
			directIngaandZekerPensioen: "Vragen over uw Direct Ingaand Zeker Pensioen",
			directIngaandKeuzePensioen: "Vragen over uw Direct Ingaand Keuze Pensioen",
			P123: "Waar vind ik mijn P 1-2-3, uitvoeringsovereenkomst en Pensioenreglementen?",
			upoFactorA: {
				question: "Waar vind ik mijn UPO en factor A?",
				answer: "Uw factor A is opgenomen in het UPO bij het kopje ‘Heeft u vragen?’ op de laatste pagina van het UPO. Het UPO staat voor u klaar in de Allianz <a href='https://allianzpensioenkluis.nl/app/login/' target='_blank'>pensioenkluis</a>."
			},
			overFactorA: {
				question: "Wat moet ik weten over factor A?",
				answer: "Op het UPO vindt u uw factor A. Als u een lijfrente heeft, kunt u met de factor A uw jaarruimte berekenen. Voor de jaarruimte gebruikt u steeds de factor A van het jaar ervoor. Bent u uit dienst gegaan? Dan vindt u de factor A in de beëindigingsbevestiging. Deze kunt u vinden in de <a href='https://allianzpensioenkluis.nl/app/login/' target='_blank'>pensioenkluis</a> onder het kopje “Polis”."
			},
			soonToBeRetired: {
				question: "Ik ga bijna met pensioen. Waar geef ik mijn pensioenkeuze door?",
				answer: `U ontvangt van ons een brief over het regelen van uw pensioenuitkering. Ga naar <a href="https://www.mijnpensioenkeuze.nl" target="_blank">www.mijnpensioenkeuze.nl</a> voor alle informatie en het doorgeven van uw pensioenkeuze`
			},
			emailChange: {
				question: "Waarom mijn prive e-mailadres opgeven?",
				answer: `In de meeste gevallen hebben wij van uw werkgever uw zakelijke e-mailadres ontvangen. Om u nu en in de toekomst zo goed mogelijk te kunnen bereiken verzoeken wij u direct uw privé e-mailadres op te geven. Dit doet u eenvoudig bij Mijn gegevens.`
			},
			participationNumber: {
				question: "Waar vind ik mijn deelnamenummer?",
				answer: `Op de pagina <a href="/pensiondetails.html">Mijn pensioengegevens</a> staat uw deelnamenummer`
			},
			pensionEstimateComparison: {
				question: "Waarom verschilt mijn te verwachten pensioen op MijnPensioenOverzicht.nl met het Allianz Persoonlijk Pensioenportaal?",
				answer: `In het Allianz Pensioenportaal gebruiken we, in tegenstelling tot <a href="https://www.mijnpensioenoverzicht.nl" target="_blank">MijnPensioenOverzicht.nl</a>, reële rendementen.
						 Reële rendementen houdt in dat we u laten zien wat u met de verwachte prijzen van straks kan kopen met uw pensioen van nu. We houden er bijvoorbeeld rekening mee dat we verwachten dat de prijzen met ongeveer 2% per jaar stijgen. We noemen dat ook wel koopkracht.
						 Op <a href="https://www.mijnpensioenoverzicht.nl" target="_blank">MijnPensioenOverzicht.nl</a> ziet u uw pensioen wat u kunt verwachten op basis van de prijzen van nu. Dat is dus meer. U krijgt aan het einde natuurlijk uw te verwachten pensioen op basis van de prijs van nu.`
			},
			instructionVideos: {
				title: "Instructievideo's",
				introText: "Het Persoonlijk Pensioen Portaal is dé centrale plaats waar u alles kunt vinden over uw pensioen bij Allianz. U ziet hoeveel kapitaal u al heeft opgebouwd en hoeveel pensioen u naar verwachting krijgt als u met pensioen gaat. Ook kunt u uw persoonlijke keuzes aan ons doorgeven en eenvoudig diverse scenario’s berekenen, zoals eerder met pensioen gaan of extra geld inleggen. Ontvangt u al een uitkering van Allianz? Dan zijn sommige onderdelen die in de instructievideo’s worden getoond niet beschikbaar voor u.",
				links: {
					howDoesPortalWork: {
						link: "Hoe werkt mijn Allianz Persoonlijk Pensioen Portaal?",
						description: "In deze video leggen we uit hoe het Allianz Persoonlijk Pensioen Portaal werkt."
					},
					howFillPensionProfile: {
						link: "Hoe vul ik mijn pensioenprofiel 100%?",
						description: "In deze video leggen we uit hoe u uw pensioenprofiel 100% vult om zo een volledig en betrouwbaar overzicht te krijgen dat aansluit op uw persoonlijke situatie."
					},
					howPerformAValueTransfer: {
						link: "Hoe doe ik een waardeoverdracht?",
						description: "In deze video leggen we uit hoe u een aanvraag doet om eerder opgebouwd pensioen over te dragen naar Allianz."
					}
				}
			},
			directIngaandeLijfrente: "Vragen over uw Direct Ingaande Lijfrente",
			divorceText: `Als u uit elkaar gaat, maakt u afspraken over uw geld en bezittingen inclusief pensioen.
			Als u afspreekt het pensioen te verdelen, kunt u kiezen voor verevening of conversie.
			Bij verevening ontvangt uw ex-partner een gedeelte van uw pensioenuitkering als u met pensioen gaat.
			Als uw ex-partner overlijdt, ontvangt u uw volledige pensioen. Bij conversie ontvangt uw ex-partner een eigen pensioen
			dat ingaat op het moment dat uw ex-partner kiest. Als u geen afspraken maakt, volgt u de wettelijke verdeling.
			Uw financieel adviseur kan u hierover informeren.
			`,
			divorceTextMoreInfo: `Voor het verwerken van de echtscheiding op uw polis hebben wij meer informatie van u nodig:`,
			divorceTextPoint: `Stuur ons een kopie van het echtscheidingsconvenant;
			In het echtscheidingsconvenant staan de gemaakte afspraken vermeld over de verdeling en verdere verwerking van het pensioen.`,
			divorceTextExtended: `U kunt de documenten per mail opsturen naar <a href="mailto:pensioenen@allianz.nl" target="_blank">pensioenen@allianz.nl</a> onder vermelding van uw polisnummer.<br/><br/>
			Na de verwerking van de echtscheiding ontvangen u en uw ex-partner een bevestiging waarin de gevolgen voor uw pensioen worden toegelicht.`
		},
		pensiondetails: {
			title: "Mijn pensioengegevens",
			introLine1: "Via uw huidige werkgever bouwt u pensioen op bij {{ provider }}. Daarnaast heeft u recht op AOW van de overheid.",
			introLine2: "De hoogte van uw AOW-uitkering is afhankelijk van het aantal jaren die u in Nederland heeft gewoond. Dat kan dus afwijken van onderstaande bedrag.",
			introLine3: "Voor meer info, zie <a href='https://www.svb.nl' target='_blank'>www.svb.nl</a>",
			introLine4: "Heeft u naast dit pensioen nog andere pensioenen of spaarpotjes voor uw pensioen?",
			myPensionSchemeIntro: "Op deze pagina hebben we een overzicht voor u gemaakt van de belangrijkste punten van uw pensioenregeling. Als er iets niet duidelijk is, of u heeft een vraag kunt u deze eenvoudig via het <a href='contact.html'>contactformulier</a> aan ons stellen.",
			latestStatusIntro: "Hieronder ziet u de laatste wijziging van uw pensioenregeling.",
			introRetiree: "Hieronder ziet u een overzicht van uw huidige pensioen(en). In dit overzicht ziet u alleen uw bruto pensioenuitkeringen op jaarbasis bij {{ provider }}. Eventueel overig inkomen zoals AOW of mogelijk andere pensioenen staan hier niet in. Wilt u een totaalbeeld van uw pensioeninkomen, dan adviseren wij u om dit na te kijken op <a href='https://mijnpensioenoverzicht.nl/' target='_blank'>www.mijnpensioenoverzicht.nl</a>.",
			otherIncomeLink: "Vul uw overig inkomen aan voor een compleet overzicht.",
			totalGross: "Totaal bruto per jaar",
			totalNet: "Netto per maand vanaf pensioenleeftijd",
			uploadButton: "Upload pensioenoverzicht",
			addButton: "Handmatig invoeren",
			lifelong: "Levenslang",
			tabs: {
				myPension: "Mijn pensioen",
				myPensionScheme: "Mijn pensioenregeling",
				myPensionSchemeRetiree: "Mijn Pensioendetails",
				lastPosition: "Laatste stand"
			},
			allianz: {
				title: "Pensioen bij {{ provider }}",
				policyId: "Polisnummer",
				pensionPlan: "Pensioenregeling",
				lifecycle: "Lifecycle",
				amount: "Bedrag",
				fixedOrVariable: "Variabel/Vast",
				retireePolicyType: {
					DIP_VAST: "Vast",
					DIP_VARIABLE: "Variabel",
					DIL: "Vast",
					DIL_VARIABLE: "Variabel"
				}
			},
			otherIncome: {
				title: "Overig inkomen",
				type: "Type inkomen",
				description: "Beschrijving",
				from: "Vanaf",
				until: "Levenslang óf tot",
				amount: "Verwachte uitkering",
				error: "U heeft nog geen overige inkomens ingevuld."
			},
			removeItemModal: {
				title: "Weet u het zeker?",
				subtitle: "Weet u het zeker?",
				description: {
					prefix: "Weet u zeker dat u ",
					suffix: " wilt verwijderen?"
				},
				button: "Ja, verwijderen"
			}
		},
		planner: {
			intro: {
				notStarted: {
					title: "Begin op tijd met pensioen plannen!",
					content: "Wilt u meer of minder gaan werken? Wilt u eerder of later met pensioen? Wilt u extra bijsparen? In de planner ziet u het effect op uw pensioen. We geven u graag inzicht om gemakkelijker een keuze te maken.",
					button: "AAN DE SLAG!"
				},
				revisited: {
					title: "Ga verder met het plannen van uw pensioen!",
					content: "Wilt u meer of minder gaan werken? Wilt u eerder of later met pensioen? Wilt u extra bijsparen? In de planner ziet u het effect op uw pensioen. We geven u graag inzicht om gemakkelijker een keuze te maken.",
					button: "AAN DE SLAG!"
				}
			},
			expectancy: {
				below: "Onder verwachting",
				normal: "Volgens verwachting",
				above: "Boven verwachting"
			},
			choices: {
				title: "Stel uw pensioen samen",
				work: {
					title: "Wilt u meer of minder gaan werken?",
					question1: "Wilt u meer of minder gaan werken?",
					question2: "Uit hoeveel uur bestaat een fulltime week?",
					question3: "Hoeveel uur per week wilt u gaan werken?",
					question4: "Vanaf welke leeftijd wilt u meer of minder gaan werken?",
					question3Details: "U gaat dus",
					question3DetailsLess: "minder werken",
					question3DetailsMore: "meer werken",
					question2Max: "Een fulltime week is maximaal 40 uur.",
					question3Max: "U kunt maximaal {{ workHoursFull }} uur gaan werken.",
					question3Min: "U moet minimaal 1 uur per week werken.",
					question4Min: "De leeftijd moet in de toekomst liggen.",
					question4Max: "De leeftijd moet lager zijn dan de leeftijd waarop u met pensioen gaat."
				},
				pension: {
					title: "Wanneer wilt u met pensioen?",
					question1: "Vanaf welke leeftijd wilt u met pensioen gaan?",
					question1AOW: "Als u AOW krijgt",
					question1WorkAge: "De leeftijd moet hoger zijn dan de leeftijd waarop u minder gaat werken.",
					question1Future: "De leeftijd moet in de toekomst liggen.",
					question1Min: "U kunt op z’n vroegst op 55 jaar met pensioen.",
					question1Max: "U moet voor uw 73e met pensioen gaan."
				},
				partner: {
					title: "Wat krijgt uw partner als u overlijdt?",
					question1: "Hoeveel pensioen wilt u nalaten aan uw partner?"
				},
				extra: {
					title: "Wilt u bijsparen?",
					question1: "Wilt u extra geld storten voor een hoger pensioen?",
					question2: "Hoeveel geld wilt u extra storten?",
					question3: "Hoe vaak wilt u extra geld storten?",
					once: "Eenmalig",
					yearly: "Elk jaar",
					monthly: "Elke maand",
					ltMinExtraAmount: "De inleg moet hoger zijn dan ",
					gtMaxExtraAmountOnce: "De maximale eenmalige inleg bedraagt ",
					gtMaxExtraAmountYearly: "De maximale jaarlijkse inleg bedraagt ",
					gtMaxExtraAmountMonthly: "De maximale maandelijkse inleg bedraagt ",
					arrangePension: "Direct een extra pensioen regelen",
					investmentChoiceText: "De berekening in de planner is gebaseerd op de door u gekozen lifecycle. Deze vindt u op de pagina ",
					investmentChoiceLink: "Mijn pensioengegevens."
				}
			},
			chart: {
				title: "Uw resultaat",
				tooltip: "U kunt de rest van uw leven van dit pensioen genieten"
			},
			details: {
				showDetails: "Laat meer pensioendetails zien",
				title: "Uw pensioen details",
				subtitle: "U ziet hier per periode hoeveel u per maand krijgt.",
				switchOption1: "Bruto",
				switchOption2: "Netto",
				partnerTitle: "Voor uw partner na uw overlijden",
				otherIncome: "Voeg overig inkomen toe"
			},
			income: {
				yourIncome: "Uw bruto inkomen",
				noIncome: "U krijgt in deze periode geen pensioen of ander inkomen.",
				pension: "Pensioen",
				statePension: "AOW",
				salary: "Salaris",
				other: "Overig",
				net: "Netto"
			},
			goal: {
				title: "Pas uw pensioendoel aan",
				description: "Hoeveel pensioen heeft u nodig voor later? Uitgangspunt is 70% van uw huidige netto inkomen per maand. Maar misschien heeft u meer of minder inkomen nodig na uw pensioendatum. Bepaal uw pensioendoel.",
				amount: "Doelbedrag",
				expectedExpenses: "Uw verwachte uitgaven wanneer u met pensioen gaat",
				inputText: "Ik schat in dat ik vanaf mijn pensioendatum",
				afterInputText: "nodig heb.",
				letUsHelp1: "Weet u niet hoeveel inkomen u nodig heeft?",
				letUsHelp2: "Bepaal uw pensioendoel"
			}
		},
		valuetransfer: {
			intro: {
				notStarted: {
					title: "Wel of geen waardeover<wbr>dracht?",
					content: "Heeft u al eerder pensioen opgebouwd bij een andere werkgever? Dan kunt u er voor kiezen om een offerte aan te vragen om het pensioen over te dragen naar Allianz. We helpen u graag op weg.",
					button: "Aan de slag!"
				},
				notFinished: {
					title: "Wel of geen waardeover<wbr>dracht?",
					content: "Heeft u al eerder pensioen opgebouwd bij een andere werkgever? Dan kunt u er voor kiezen om een offerte aan te vragen om het pensioen over te dragen naar Allianz. We helpen u graag op weg.",
					button: "Vervolg de waardeoverdracht!"
				},
				finished: {
					title: "U heeft uw waarde<wbr>overdracht al aangevraagd.",
					content: "We zijn al voor u aan de slag! We houden u op de hoogte van de status van uw waardeoverdracht(en). Als we aanvullende informatie nodig hebben, dan ontvangt u een e-mail.",
					button: "Home"
				}
			},
			step1: {
				title: "Heeft u eerder pensioen opgebouwd?",
				intro: "U vindt een compleet overzicht van eerder door u opgebouwde pensioenen op mijnpensioenoverzicht.nl.<br>Upload uw pensioenoverzicht en beslis voor welke pensioenen u een offerte voor waardeoverdracht wilt ontvangen.",
				step1: {
					prefix: "Ga naar",
					suffix: "en log in met uw DigiD of met een Europees erkend inlogmiddel (eIDAS)."
				},
				step2: {
					title: "Download uw gegevens",
					description: "Controleer uw gegevens en klik op 'Bekijk mijn pensioenoverzicht'. Rechtsboven in het scherm (onder uw naam) klikt u op 'Download uw pensioenoverzicht'. Sla het bestand op als XML en ga terug naar dit portaal."
				},
				step3: {
					title: "Selecteer het XML-bestand dat u zojuist heeft opgeslagen en klik op 'Bestand uploaden'.",
					description: "geen bestand geselecteerd",
					xmlReading: "Het XML-bestand wordt gelezen...",
					xmlReadingFailed: "Het bestand kan niet worden ingelezen. Controleer of u het juiste bestand hebt geselecteerd (bijvoorbeeld pensioenoverzicht.xml)"
				}
			},
			transferablePensions: {
				title: "Selecteer de pensioenen waarvoor u een offerte wil ontvangen.",
				type: "Type",
				description: {
					text: "Het onderstaande overzicht is van {{ lastDateFileUpload | shortDate }}. Beschikt u over recentere gegevens dan kunt u deze hier ",
					link: "uploaden"
				},
				columns: {
					provider: "Uitvoerder",
					type: "Type",
					reference: "Herkenningsnummer",
					value: "Waarde",
					transfer: "Overdragen"
				},
				indicativePension: "Dit pensioen is opgebouwd in een zogeheten beschikbare premieregeling.\n\nUw ex-werkgever en u hebben beiden premies ingelegd, waarna de opbrengsten uit beleggingen na aftrek van de kosten een aanspraak heeft opgeleverd.\n\nMet dit kapitaal kunt u pensioen aankopen in de vorm van een vaste of variabele periodieke uitkering.",
				noIndicativePension: "Dit pensioen is opgebouwd in een zogeheten eindloon- of middelloonregeling.\n\nIn een eindloonregeling bouwt u een pensioen op dat gebaseerd is op uw laatste loon.\nIn een middelloonregeling bouwt u een pensioen op dat gebaseerd is op het gemiddelde loon dat u in uw hele loopbaan heeft verdiend.",
				automaticValueTransfer: "De waarde van dit pensioen wordt automatisch overgedragen naar Allianz.\nVanaf 1 januari 2019 is de ‘Wet waardeoverdracht klein pensioen’ van kracht. Hierdoor wordt de waarde van kleine al eerder opgebouwde pensioenen automatisch overgedragen naar de nieuwe pensioenuitvoerder. Meerdere kleine pensioenen worden zo samengevoegd tot één groter pensioen.\nDeze wet heeft betrekking op alle kleine pensioenen vanaf 1 januari 2018."
			},
			noTransferablePensions: {
				title: "Geen overdracht mogelijk",
				description: {
					text: "Er zijn nu geen pensioenen bekend die overgedragen kunnen worden. Beschikt u over recentere gegevens dan kunt u deze hier ",
					link: "uploaden"
				},
				homeButton: "Naar pensioen portaal"
			},
			confirmation: {
				title: "Uw Selectie",
				subtitle: "Offerte aanvragen voor waardeoverdracht naar mijn Allianz Pensioen:",
				extraInformation: "Door het bevestigen van de selectie vraagt u een offerte aan voor waardeoverdracht naar Allianz. Het Allianz pensioen product kent geen garantie. Heeft u nu wel een vorm van garantie? Houd er dan rekening mee dat deze garantie komt te vervallen. Denk er dus goed over na voordat u deze waarden overdraagt.",
				automaticValueTransfer: "(automatische waardeoverdracht)",
				noTransfer: "U heeft geen pensioenen voor waardeoverdracht geselecteerd.",
				missingEmail: "Uw e-mail is nog niet bij ons bekend. Vul deze in om door te gaan.",
				button: "Bevestig selectie"
			},
			outro: {
				title: "U heeft een offerte aangevraagd voor waardeoverdracht",
				subtitle: "We gaan voor u aan de slag! We houden u op de hoogte. Als we aanvullende informatie nodig hebben, dan ontvangt u een e-mail.",
				noTransferDescription: "U heeft geen pensioenen geselecteerd voor waardeoverdracht.",
				call2action: {
					title: "Vul nu uw pensioenprofiel verder aan!",
					content: "U heeft zojuist bepaald of u eerder opgebouwd pensioen bij een vorige werkgever wilt overdragen naar Allianz. Wat is uw risicoprofiel voor het pensioen dat u nu opbouwt? Zorg er voor dat uw beleggingen nog beter bij uw persoonlijke situatie passen. Vul daarom uw pensioenprofiel verder aan en wij beleggen uw pensioenpremie nog meer op maat voor u.",
					button: "Pensioenprofiel aanvullen"
				},
				homeButton: "Home"
			}
		},
		extrasavings: {
			intro: {
				waitForData: "Uw gegevens worden verzameld, even geduld a.u.b.",
				notStarted: {
					title: "Wilt u meer pensioen? Dat kan!",
					titlePensionGap: "Wilt u meer pensioen? Dat kan!",
					contentTaxes: "Uw werkgever biedt u de mogelijkheid om binnen de collectieve pensioenregeling zelf extra pensioen op te bouwen met bijsparen. Gebruik bijvoorbeeld een gedeelte van uw vakantiegeld om uw pensioen aan te vullen. Benieuwd wat het effect is op uw pensioen?",
					contentHolidayPay: "Uw werkgever biedt u de mogelijkheid om binnen de collectieve pensioenregeling zelf extra pensioen op te bouwen met bijsparen. Gebruik bijvoorbeeld een gedeelte van uw vakantiegeld om uw pensioen aan te vullen. Benieuwd wat het effect is op uw pensioen?",
					contentPensionGap: "U heeft een pensioengat; uw pensioendoel is hoger dan uw verwachte pensioen. Uw werkgever biedt u de mogelijkheid om binnen de collectieve pensioenregeling zelf extra pensioen op te bouwen met bijsparen. Benieuwd wat het effect is op uw pensioengat?",
					content: "Uw werkgever biedt u de mogelijkheid om binnen de collectieve pensioenregeling zelf extra pensioen op te bouwen met bijsparen. Gebruik bijvoorbeeld een gedeelte van uw vakantiegeld om uw pensioen aan te vullen. Benieuwd wat het effect is op uw pensioen?",
					button: "AAN DE SLAG!"
				}
			},
			currentsituation: {
				title: "Zo staat u er voor",
				description: {
					pensionGoal: {
						prefix: "Hieronder ziet u een overzicht van uw verwachte pensioen en uw pensioendoel. Is uw pensioendoel hoger dan uw verwachte pensioen, dan heeft u een pensioengat. U kunt dit pensioengat verkleinen door zelf extra pensioen op te bouwen. Ook als u geen pensioengat heeft, kunt u zelf extra pensioen opbouwen.",
						link: "hier",
						suffix: " uw pensioendoel aanpassen."
					},
					pensionIncome: {
						prefix: "Uw pensioensituatie kan ook afhankelijk zijn van pensioen van vorige werkgevers en overige inkomsten. Heeft u uw deze al toegevoegd? U kunt ",
						link: "hier",
						suffix: " uw pensioen van vorige werkgevers uploaden, of uw overige inkomsten toevoegen."
					}
				},
				btnDetails: "Meer details",
				arrangeExtraSavings: "Wilt u zelf het juiste bijspaar bedrag bepalen? Ga dan naar de ",
				linkToPlanner: "planner."
			},
			extrapension: {
				title: "Extra pensioen opbouwen met bijsparen",
				description: "Uw werkgever biedt u de mogelijkheid om binnen de collectieve pensioenregeling zelf extra pensioen op te bouwen met bijsparen. Dat betekent dat u zelf geld inlegt voor de opbouw van uw pensioen. Geef aan hoe vaak u geld wilt inleggen en hoeveel geld u wilt inleggen en wij tonen het effect op uw pensioen.",
				searchAdvisorlink: "Zoek een financieel adviseur.",
				requestButton: "Ja ik wil extra inleggen",
				choices: {
					frequency: {
						title: "Hoe vaak wilt u geld inleggen voor de opbouw van extra pensioen?",
						once: "Eenmalig",
						monthly: "Maandelijks"
					},
					deposit: {
						title: "Wat wordt vanaf nu uw totaal maandelijks bijspaar-bedrag?",
						fiscalRoomMonthly: {
							prefix: `De inleg voor extra pensioen is fiscaal aftrekbaar van uw brutoloon. Dat is niet onbeperkt.
							Hiervoor geldt een 'fiscale jaarruimte'. In de onderstaande berekening houden we daar rekening mee.
							Ook heeft de extra inleg invloed op de vaststelling van uw SV Loon.
							Het SV Loon is de basis voor het vaststellen van diverse uitkeringen door het UWV.`,
							tooltip: "Heeft u in het verleden geen gebruik gemaakt van de fiscale jaarruimte? Dan kunt u nu mogelijk meer geld inleggen dan het jaarlijkse maximum met de zogeheten reserveringsruimte. Raadpleeg een financieel adviseur of de website van de Belastingdienst voor meer informatie.",
							suffix: "U kunt dit jaar maximaal € {{ fiscalRoomMonthly }} per maand bijsparen. Let op: Het bedrag dat u hieronder invult tellen we op bij het bedrag dat u misschien al inlegt.",
							reduceMonthlyContributionMessage: "Wanneer u uw maandelijkse bijspaarbedrag wilt verlagen, dan kunt u dit alleen per mail aan ons doorgeven. Dit kan via het e-mailadres pensioenen@allianz.nl. Wij ontvangen graag een verzoek onder vermelding van uw deelnamenummer met het gewenste maandelijkse bijspaarbedrag. Wij voeren de wijziging de eerstkomende maand voor u door."
						},
						fiscalRoomOnce: "U kunt dit jaar fiscaal maximaal € {{ fiscalRoomOnce }} eenmalig bijsparen",
						ltMinExtraAmount: "De inleg moet hoger zijn dan ",
						gtMaxExtraAmountOnce: "De maximale eenmalige inleg bedraagt ",
						gtMaxExtraAmountMonthly: "De maximale maandelijkse inleg bedraagt ",
						tooltip: `De inleg voor extra pensioen is fiscaal aftrekbaar van uw brutoloon. Dat is niet onbeperkt.
						Hiervoor geldt een “fiscale jaarruimte”. In de onderstaande berekening houden we daar rekening mee. Het bedrag dat u hier invult tellen we op bij het bedrag dat u misschien al inlegt.
						Ook heeft de extra inleg invloed op de vaststelling van uw SV Loon. Het SV Loon is de basis voor het vaststellen van diverse uitkeringen door het UWV.`
					},
					onceAdvisor: "Van de Belastingdienst mag u niet onbeperkt extra pensioen opbouwen; er geldt een zogeheten fiscale jaarruimte. Wij houden daar rekening mee. Heeft u in het verleden geen gebruik gemaakt van de fiscale jaarruimte? Dan kunt u nu mogelijk meer geld inleggen dan het jaarlijkse maximum met de zogeheten reserveringsruimte. Wij kunnen niet vast stellen of u recht heeft op deze reserveringsruimte. Raadpleeg een financieel adviseur of de website van de Belastingdienst voor meer informatie.<br><br>Allianz aanvaardt geen aansprakelijkheid voor inleg voor de opbouw van extra pensioen die niet in uw fiscale jaarruimte en/of in uw reserveringsruimte past."
				}
			},
			detailsModal: {
				titlePensionGoal: "Uw pensioendoel",
				titleExpectedPension: "Uw verwachte pensioen"
			},
			chart: {
				pensionExpected: "Verwacht netto pensioen",
				pensionExtra: "Met extra netto inleg",
				pensionGoal: "Doel"
			},
			cancel: "Afsluiten",
			confirmation: {
				title: "Geld inleggen voor extra pensioen",
				cardTitle: "U kiest er voor om",
				cardContent: "Door verder te gaan, gaan we het voor u regelen. We sturen u een e-mail met de bevestiging, en ook zorgen we ervoor dat uw werkgever uw verzoek doorkrijgt. En vanaf uw volgende salaris, of het salaris daarna houdt uw werkgever uw extra inleg in van uw bruto salaris.<br><br>Het is belangrijk dat u weet wat er nu gaat gebeuren. Door verder te gaan geeft u aan dat u dit weet, of dat u een financieel adviseur heeft geraadpleegd.",
				missingEmail: "Uw e-mail is nog niet bij ons bekend. Vul deze in om door te gaan.",
				button: "Bevestigen",
				extraPension: {
					title: "Ik regel extra pensioen:",
					frequency: "Frequentie:",
					extrasavings: "Extra inleg:"
				},
				confirmation: {
					title: "Ik ga akkoord met:",
					option1: "Ik start met bijsparen.",
					option2: "De werkgever houdt de extra inleg in van mijn bruto salaris.",
					option3: "Ik begrijp de voorwaarden."
				}
			},
			confirmed: {
				title: "Wij hebben uw werkgever geïnformeerd",
				text: "U bent nu dichter bij uw pensioendoel!<br>Wij gaan voor u aan de slag om uw extra inleg voor u te regelen. U ontvangt van ons een bevestiging in uw inbox."
			},
			outro: {
				title: "Dat was het voor nu!",
				text: "We gaan voor u aan de slag.",
				canceltext: "U bent nu klaar. Door op home te klikken gaat u terug naar uw dashbaord.",
				card1: {
					title: "Overige pensioenen updaten",
					description: "Zorg er voor dat al uw gegevens up to date zijn. Upload uw laatste gegevens van "
				},
				card2: {
					title: "Pensioendoel aanpassen",
					description: "Misschien wilt u uw pensioendoel opnieuw vaststellen? Beantwoord de vragen en stel uw Pensioendoel vast."
				},
				card3: {
					title: "Ga terug naar de startpagina",
					description: "U bent klaar. Ga terug naar de startpagina."
				}
			}
		},
		customerJourney: {
			outro: {
				choose: "Kies"
			}
		},
		documents: {
			title: "Documenten",
			paragraph: "Hieronder ziet u een overzicht van uw pensioendocumenten.",
			paragraphDigital: "Hieronder ziet u een overzicht van uw pensioendocumenten. Mist u een document? Kijk voor een volledig overzicht op ",
			otherDocuments: "Andere documenten zoals uw UPO en Pensioen 1-2-3 vindt u in Mijn Pensioenkluis.",
			accessMessage: "Let op! U kunt pas inloggen in Mijn Pensioenkluis als het eerste document hierin is geplaatst. U ontvangt hier automatisch een bericht van.",
			vaultUrl: "https://allianzpensioenkluis.nl/app/login/",
			gotoPensionVault: "Naar mijn pensioenkluis",
			year: "Jaar",
			yearAll: "Alle jaren",
			type: "Type",
			typeAll: "Alle types",
			all: "Alle",
			link: "Mijn Pensioenkluis.",
			noDocuments: "Er zijn geen documenten gevonden.",
			columntitles: {
				date: "Datum",
				type: "Type",
				title: "Naam"
			},
			types: {
				mutation: "Mutatie",
				acknowledgement: "Deelnamebevestiging",
				policy: "Polis"
			},
			titles: {
				mutatiebevestiging: "Mutatiebevestiging",
				deelnamebevestiging: "Deelnamebevestiging",
				beeindigingsbevestiging: "Beëindigingsbevestiging",
				mutatiebevestigingscheiding: "Mutatiebevestiging - Echtscheiding",
				policy: "Polis"
			}
		},
		inbox: {
			title: "Inbox",
			noMessages: "Je hebt nog geen berichten van ons ontvangen."
		},
		defaulterrors: {
			required: "Dit veld is verplicht.",
			type: "Dit veld bevat geen geldige waarde.",
			values: "Dit veld bevat geen geldige waarde.",
			valid: "Dit veld bevat geen geldige waarde.",
			maxLength: "Dit veld bevat geen geldige waarde.",
			minLength: "Dit veld bevat geen geldige waarde.",
			maximum: "Dit veld bevat geen geldige waarde.",
			minimum: "Dit veld bevat geen geldige waarde.",
			pattern: "Dit veld bevat geen geldige waarde."
		},
		lifecyclenames: {
			AAP2013_ACT_DEF_25:	"Allianz Actieve Lifecycle Defensief 25% doorbeleggen",
			AAP2013_ACT_NEU_25:	"Allianz Actieve Lifecycle Neutraal 25% doorbeleggen",
			AAP2013_ACT_NEU_50:	"Allianz Actieve Lifecycle Neutraal 50% doorbeleggen",
			AAP2013_ACT_OFF_25:	"Allianz Actieve Lifecycle Offensief 25% doorbeleggen",
			AAP2013_ACT_OFF_50:	"Allianz Actieve Lifecycle Offensief 50% doorbeleggen",
			AAP2013_ACT_OFF_75:	"Allianz Actieve Lifecycle Offensief 75% doorbeleggen",
			AAP2013_DB_ACT:	"Allianz Actieve Lifecycle Doorbeleggen",
			AAP2013_DB_PAS:	"Allianz Lifecycle Doorbeleggen",
			AAP2013_PAS_DEF_25:	"Allianz Lifecycle Defensief 25% doorbeleggen",
			AAP2013_PAS_NEU_25:	"Allianz Lifecycle Neutraal 25% doorbeleggen",
			AAP2013_PAS_NEU_50:	"Allianz Lifecycle Neutraal 50% doorbeleggen",
			AAP2013_PAS_OFF_25:	"Allianz Lifecycle Offensief 25% doorbeleggen",
			AAP2013_PAS_OFF_50:	"Allianz Lifecycle Offensief 50% doorbeleggen",
			AAP2013_PAS_OFF_75:	"Allianz Lifecycle Offensief 75% doorbeleggen",
			AAP2013_MAN_DB:	"Doorbeleglifecycle",
			AAP2013_MAN_DEF: "Lifecycle Defensief",
			AAP2013_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			AAP2013_MAN_NEU: "Lifecycle Neutraal",
			AAP2013_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			AAP2013_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			AAP2013_MAN_OFF: "Lifecycle Offensief",
			AAP2013_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			AAP2013_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			AAP2013_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen",
			AAP2014_ACT_DEF_25:	"Allianz Actieve Lifecycle Defensief 25% doorbeleggen",
			AAP2014_ACT_NEU_25:	"Allianz Actieve Lifecycle Neutraal 25% doorbeleggen",
			AAP2014_ACT_NEU_50:	"Allianz Actieve Lifecycle Neutraal 50% doorbeleggen",
			AAP2014_ACT_OFF_25:	"Allianz Actieve Lifecycle Offensief 25% doorbeleggen",
			AAP2014_ACT_OFF_50:	"Allianz Actieve Lifecycle Offensief 50% doorbeleggen",
			AAP2014_ACT_OFF_75:	"Allianz Actieve Lifecycle Offensief 75% doorbeleggen",
			AAP2014_DB_ACT:	"Allianz Actieve Lifecycle Doorbeleggen",
			AAP2014_DB_PAS:	"Allianz Lifecycle Doorbeleggen",
			AAP2014_PAS_DEF_25:	"Allianz Lifecycle Defensief 25% doorbeleggen",
			AAP2014_PAS_NEU_25:	"Allianz Lifecycle Neutraal 25% doorbeleggen",
			AAP2014_PAS_NEU_50:	"Allianz Lifecycle Neutraal 50% doorbeleggen",
			AAP2014_PAS_OFF_25:	"Allianz Lifecycle Offensief 25% doorbeleggen",
			AAP2014_PAS_OFF_50:	"Allianz Lifecycle Offensief 50% doorbeleggen",
			AAP2014_PAS_OFF_75:	"Allianz Lifecycle Offensief 75% doorbeleggen",
			AAP2014_MAN_DB:	"Doorbeleglifecycle",
			AAP2014_MAN_DEF: "Lifecycle Defensief",
			AAP2014_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			AAP2014_MAN_NEU: "Lifecycle Neutraal",
			AAP2014_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			AAP2014_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			AAP2014_MAN_OFF: "Lifecycle Offensief",
			AAP2014_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			AAP2014_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			AAP2014_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen",
			ANP_ACT_DEF_25:	"Allianz Actieve Lifecycle Defensief 25% doorbeleggen",
			ANP_ACT_NEU_25:	"Allianz Actieve Lifecycle Neutraal 25% doorbeleggen",
			ANP_ACT_NEU_50:	"Allianz Actieve Lifecycle Neutraal 50% doorbeleggen",
			ANP_ACT_OFF_25:	"Allianz Actieve Lifecycle Offensief 25% doorbeleggen",
			ANP_ACT_OFF_50:	"Allianz Actieve Lifecycle Offensief 50% doorbeleggen",
			ANP_ACT_OFF_75:	"Allianz Actieve Lifecycle Offensief 75% doorbeleggen",
			ANP_DB_ACT:	"Allianz Actieve Lifecycle Doorbeleggen",
			ANP_DB_PAS:	"Allianz Lifecycle Doorbeleggen",
			ANP_PAS_DEF_25:	"Allianz Lifecycle Defensief 25% doorbeleggen",
			ANP_PAS_NEU_25:	"Allianz Lifecycle Neutraal 25% doorbeleggen",
			ANP_PAS_NEU_50:	"Allianz Lifecycle Neutraal 50% doorbeleggen",
			ANP_PAS_OFF_25:	"Allianz Lifecycle Offensief 25% doorbeleggen",
			ANP_PAS_OFF_50:	"Allianz Lifecycle Offensief 50% doorbeleggen",
			ANP_PAS_OFF_75: "Allianz Lifecycle Offensief 75% doorbeleggen",
			ANP_MAN_DB:	"Doorbeleglifecycle",
			ANP_MAN_DEF: "Lifecycle Defensief",
			ANP_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			ANP_MAN_NEU: "Lifecycle Neutraal",
			ANP_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			ANP_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			ANP_MAN_OFF: "Lifecycle Offensief",
			ANP_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			ANP_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			ANP_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen",
			PPI_DB_PAS:	"Allianz Doorbeleglifecycle",
			PPI_PAS_DEF: "Allianz Lifecycle Defensief",
			PPI_PAS_NEU: "Allianz Lifecycle Neutraal",
			PPI_PAS_OFF: "Allianz Lifecycle Offensief",
			PPI_PAS_DEF_25:	"Allianz Lifecycle Defensief 25% Doorbeleggen",
			PPI_PAS_NEU_25:	"Allianz Lifecycle Neutraal 25% Doorbeleggen",
			PPI_PAS_NEU_50:	"Allianz Lifecycle Neutraal 50% Doorbeleggen",
			PPI_PAS_OFF_25:	"Allianz Lifecycle Offensief 25% Doorbeleggen",
			PPI_PAS_OFF_50:	"Allianz Lifecycle Offensief 50% Doorbeleggen",
			PPI_PAS_OFF_75:	"Allianz Lifecycle Offensief 75% Doorbeleggen",
			PPI_MAN_DB:	"Doorbeleglifecycle",
			PPI_MAN_DEF: "Lifecycle Defensief",
			PPI_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			PPI_MAN_NEU: "Lifecycle Neutraal",
			PPI_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			PPI_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			PPI_MAN_OFF: "Lifecycle Offensief",
			PPI_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			PPI_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			PPI_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen",
			PPRP_AAP2013_ACT_DEF: "Allianz Actieve Lifecycle Defensief",
			PPRP_AAP2013_ACT_NEU: "Allianz Actieve Lifecycle Neutraal",
			PPRP_AAP2013_ACT_OFF: "Allianz Actieve Lifecycle Offensief",
			PPRP_AAP2013_PAS_DEF: "Allianz Lifecycle Defensief",
			PPRP_AAP2013_PAS_NEU: "Allianz Lifecycle Neutraal",
			PPRP_AAP2013_PAS_OFF: "Allianz Lifecycle Offensief",
			PPRP_AAP2014_ACT_DEF: "Allianz Actieve Lifecycle Defensief",
			PPRP_AAP2014_ACT_NEU: "Allianz Actieve Lifecycle Neutraal",
			PPRP_AAP2014_ACT_OFF: "Allianz Actieve Lifecycle Offensief",
			PPRP_AAP2014_PAS_DEF: "Allianz Lifecycle Defensief",
			PPRP_AAP2014_PAS_NEU: "Allianz Lifecycle Neutraal",
			PPRP_AAP2014_PAS_OFF: "Allianz Lifecycle Offensief",
			PPRP_ANP_ACT_DEF: "Allianz Actieve Lifecycle Defensief",
			PPRP_ANP_ACT_NEU: "Allianz Actieve Lifecycle Neutraal",
			PPRP_ANP_ACT_OFF: "Allianz Actieve Lifecycle Offensief",
			PPRP_ANP_PAS_DEF: "Allianz Lifecycle Defensief",
			PPRP_ANP_PAS_NEU: "Allianz Lifecycle Neutraal",
			PPRP_ANP_PAS_OFF: "Allianz Lifecycle Offensief",
			AAP2013_AP_Def:	"Allianz Pensioen Beleggen Defensief",
			AAP2013_AP_Gar1: "Beleggen met garantie 1%",
			AAP2013_AP_Neut: "Allianz Pensioen Beleggen Neutraal",
			AAP2013_AP_Off:	"Allianz Pensioen Beleggen Offensief",
			P_PRD_PRC_RP213: "Defensief",
			P_PRD_PRC_RP214: "Neutraal",
			P_PRD_PRC_RP215: "Offensief",
			P_PRD_PRC_RP216: "Standaard",
			P_PRD_PRC_RP218: "Zeer Defensief 1%",
			AAP2014_AP_Def:	"Allianz Pensioen Beleggen Defensief",
			AAP2014_AP_Gar1: "Beleggen met garantie 1%",
			AAP2014_AP_Neut: "Allianz Pensioen Beleggen Neutraal",
			AAP2014_AP_Off:	"Allianz Pensioen Beleggen Offensief",
			P_PRD_PRC_RP219: "Defensief",
			P_PRD_PRC_RP220: "Neutraal",
			P_PRD_PRC_RP221: "Offensief",
			P_PRD_PRC_RP222: "Standaard",
			P_PRD_PRC_RP223: "Zeer Defensief 1%",
			P_PRD_PRC_RP244: "Defensief",
			P_PRD_PRC_RP245: "Neutraal",
			P_PRD_PRC_RP246: "Offensief",
			P_PRD_PRC_RP247: "Standaard",
			P_PRD_PRC_RP248: "Zeer Defensief 1%",
			P_PRD_PRC_RP251: "Vrije investering toegestaan",
			P_PRD_PRC_RP252: "Vrije investering toegestaan",
			P_PRD_PRC_RP253: "Vrije investering toegestaan",
			P_PRD_PRC_RP287: "Vrije investering toegestaan",
			P_PRD_PRC_RP249: "Zeer Defensief 1%",
			P_PRD_PRC_RP250: "Vrije investering toegestaan",
			P_PROD_PROC_RP47: "Defensief",
			P_PROD_PROC_RP48: "Neutraal",
			P_PROD_PROC_RP49: "Offensief",
			P_PROD_PROC_RP50: "Standaard",
			P_PROD_PROC_RP51: "Zeer Defensief",
			P_PRD_PRC_RP254: "PPI Licht Offensief",
			P_PRD_PRC_RP255: "PPI Defensief",
			P_PRD_PRC_RP256: "PPI Behoudend",
			P_PRD_PRC_RP257: "PPI Neutraal",
			P_PRD_PRC_RP258: "PPI Offensief",
			AAP_2023_FREE_INVEST: "Vrije investering toegestaan",
			AAP_2023_MAN_DB: "Doorbeleglifecycle",
			AAP_2023_MAN_DEF: "Lifecycle Defensief",
			AAP_2023_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			AAP_2023_MAN_NEU: "Lifecycle Neutraal",
			AAP_2023_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			AAP_2023_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			AAP_2023_MAN_OFF: "Lifecycle Offensief",
			AAP_2023_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			AAP_2023_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			AAP_2023_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen",
			ANP_2023_FREE_INVEST: "Vrije investering toegestaan",
			ANP_2023_MAN_DB: "Doorbeleglifecycle",
			ANP_2023_MAN_DEF: "Lifecycle Defensief",
			ANP_2023_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			ANP_2023_MAN_NEU: "Lifecycle Neutraal",
			ANP_2023_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			ANP_2023_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			ANP_2023_MAN_OFF: "Lifecycle Offensief",
			ANP_2023_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			ANP_2023_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			ANP_2023_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen",
			PPI_2023_FREE_INVEST: "Vrije investering toegestaan",
			PPI_2023_MAN_DB: "Doorbeleglifecycle",
			PPI_2023_MAN_DEF: "Lifecycle Defensief",
			PPI_2023_MAN_DEF_25: "Lifecycle Defensief 25% Doorbeleggen",
			PPI_2023_MAN_NEU: "Lifecycle Neutraal",
			PPI_2023_MAN_NEU_25: "Lifecycle Neutraal 25% Doorbeleggen",
			PPI_2023_MAN_NEU_50: "Lifecycle Neutraal 50% Doorbeleggen",
			PPI_2023_MAN_OFF: "Lifecycle Offensief",
			PPI_2023_MAN_OFF_25: "Lifecycle Offensief 25% Doorbeleggen",
			PPI_2023_MAN_OFF_50: "Lifecycle Offensief 50% Doorbeleggen",
			PPI_2023_MAN_OFF_75: "Lifecycle Offensief 75% Doorbeleggen"
		},
		riskprofiles: {
			RiskProfile_Geen: "Geen",
			defensiefklant: "Defensief",
			neutraalklant: "Neutraal",
			offensiefklant: "Offensief",
			standaardklant: "Standaard",
			zeerdefensiefklant: "Defensief",
			lichtoffensiefklnt: "Offensief",
			behoudendklant: "Defensief"
		},
		civilStatusTypes: {
			SINGLE: "Alleenstaand",
			COHABITATION: "Samenwonend",
			LEGAL_COHABITATION: "Samenwonend met contract",
			CIVILPARTNERSHIP: "Geregistreerd partnerschap",
			MARRIED: "Getrouwd"
		}
	}
}

export default options
